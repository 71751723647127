import * as Yup from "yup";

export const CURRENCY_REGEX = /^[0-9]*([.][0-9]{1,2})?$/;
export const NUMBERS_REGEX = /^[0-9]+$/;
export const FLOAT_NUMBERS_REGEX = /^[0-9.]+$/;
export const NUMBER_DISABLED_CHARECTERS = ["-", "+", "e"];
export const PHONE_REG_EXP =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const EMAIL_REG_EXP =
  // eslint-disable-next-line max-len
  /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i;

export const SPECIAL_CHARACTERS_REGEX = /^([a-zA-Z0-9 _#&-])*$/;
export const COMMA_REGEX = /[,]/;

export const EMAILS_LIST_VALIDATION_SCHEMA = Yup.object().shape({
  emails: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
});

export const NAME_LIST_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string()),
});

import React, { ChangeEvent, useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import useStyles from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";

import { APIDiscounts } from "../../../../api";
import { IDiscountCode } from "../../../../api/discounts/index.types";
import usePagination from "../../../../hooks/usePagination";
import { ContainerWithLoader, PaginationWithLimit } from "../../../../components/ui";

const ReportTableWithDateSelect = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [codeStartsWith, setCodeStartsWith] = useState("");
  const [report, setReport] = useState<IDiscountCode[]>([]);

  const { count, setCount, page, setPage, limit, setLimit } = usePagination();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getDiscountCodesReport = async (code: string) => {
    setIsLoading(true);
    try {
      const response = await APIDiscounts.getDiscountCodesReportRequest({
        limit: +limit,
        codeStartsWith: code,
        offset: page,
      });
      const { report: responseReport, count: resCount } = response.data;

      setCount(resCount);
      setReport(responseReport);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getDiscountCodesReport(codeStartsWith);
    })();
  }, [codeStartsWith, limit, page]);

  const handleChangeCodeStartsWith = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCodeStartsWith(e.target.value);
  };

  const handleClickTableRow = (codeId: string) => history.push(`/discount/${codeId}`);

  return (
    <div>
      <div>
        <TextField
          value={codeStartsWith}
          onChange={handleChangeCodeStartsWith}
          label="Code starts with"
          variant="outlined"
        />
      </div>

      <div className={cx(generalClasses.containerBg, classes.centered)}>
        <ContainerWithLoader
          report={report}
          isLoading={isLoading}
          noResultsMessage="There are no results"
        >
          <div>
            <table className={generalClasses.table}>
              <thead>
                <tr>
                  <td>Code</td>
                  <td>Total</td>
                </tr>
              </thead>

              <tbody>
                {report.map(({ total, dc_code, dc_discount_code_id }) => (
                  <tr
                    key={`${dc_discount_code_id}_${dc_code}`}
                    onClick={() => handleClickTableRow(dc_code)}
                  >
                    <td>{dc_code}</td>
                    <td>{total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ContainerWithLoader>

        <PaginationWithLimit
          count={count}
          page={page}
          limit={limit}
          setPageState={setPage}
          setLimit={setLimit}
        />
      </div>
    </div>
  );
};

export default ReportTableWithDateSelect;

import React from "react";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSelector } from "react-redux";
import moment from "moment";

import { useComplianceSearchContext } from "../../../../../context";
import TableDataCellWithSort from "../../../../../components/ui/TableDataCellWithSort";
import { TBy } from "../../../../../reducers/customersReducer";
import newSortBy from "../../../../../utils/newSortBy";
import useGeneralStyles from "../../../../../useGeneralStyles";
import { DATE_FORMAT_hhmmssAzz } from "../../../../../constants";
import copyToClipboardInXlsFormat, {
  IColum,
} from "../../../../../utils/copyToClipboardInXlsFormat";

const COLUMNS: Array<IColum> = [
  {
    by: "timestamp",
    title: "Timestamp",
    path: "timestamp",
    copyToClipBoardCallBack: (value) =>
      moment(value).tz("America/Chicago").format(DATE_FORMAT_hhmmssAzz),
  },
  {
    by: "email",
    title: "Email",
    path: "account.email",
  },
  {
    by: "ip",
    title: "Ip",
    path: "ip",
  },
  {
    by: "success",
    title: "Success",
    path: "success",
    copyToClipBoardCallBack: (value) => (value ? "✔" : "✘"),
  },
];

const TableHead = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const { data } = useSelector((state) => state.complianceSearch.logins);
  const {
    logins_table: { sortBy, setSortBy },
  } = useComplianceSearchContext();
  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });

  const handleCopyToClipBoard = () => copyToClipboardInXlsFormat(data.rows, COLUMNS);

  return (
    <thead>
      <tr className={generalClasses.tableName}>
        <td colSpan={4}>
          <IconButton size="small" onClick={handleCopyToClipBoard}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
          <span>Log of logins</span>
        </td>
      </tr>
      <tr>
        {COLUMNS.map((r) => (
          <TableDataCellWithSort
            key={r.by}
            sortBy={sortBy}
            by={r.by}
            title={r.title}
            newSortBy={handleSortBy}
          />
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;

import React from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "../../../useStyles";
import useGeneralStyles from "../../../../../useGeneralStyles";
import { ComplianceSearchActions } from "../../../../../reducers/ComplianceSearchReducer/actions";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import SearchByListValues from "../../SearchByListValues";
import { ContainerWithLoader } from "../../../../../components/ui";
import { ComplianceAPI } from "../../../../../api/compliance";
import { getQueryStringByStringValues } from "../../../utils";
import { EMAILS_LIST_VALIDATION_SCHEMA } from "../../../../../utils/validation";

const SubscriptionStatusTable = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const { data, loading } = useSelector((state) => state.complianceSearch.plansStatus);

  const fetchData = async (values: { emails: string }) => {
    try {
      dispatch(ComplianceSearchActions.fetchComplianceSearchPlansStatusRequest());
      const response = await ComplianceAPI.getAccountPlansStatus(
        getQueryStringByStringValues("emails", values.emails)
      );
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchPlansStatusSuccess(response.data.accounts)
      );
    } catch (error) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchPlansStatusFailed());
      console.log("error fetching SubscriptionStatusTable  data", error);
    }
  };

  return (
    <div>
      <b className={classes.title}>Search active/canceled subscriptions</b>
      <SearchByListValues
        validationSchema={EMAILS_LIST_VALIDATION_SCHEMA}
        isLoading={loading}
        fetchData={fetchData}
        placeholder="john@gmail.com, doe@gmail.com e.t.c ..."
        label="Emails"
        name="emails"
        initialValues={{ emails: "" }}
      />

      <div className={classes.tableContainer}>
        <ContainerWithLoader isLoading={loading} report={data}>
          <div className={generalClasses.tableHorizontalScroll}>
            <table
              className={cx(
                generalClasses.table,
                generalClasses.smTable,
                generalClasses.tableWithoutHover,
                generalClasses.noTextWrap
              )}
            >
              <TableHead />
              <TableBody />
            </table>
          </div>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default SubscriptionStatusTable;

import React, { useEffect, useState } from "react";

import useStyles from "./useStyles";
import { APIBilling } from "../../../../api";
import useGeneralStyles from "../../../../useGeneralStyles";
import { IntlFormatUSD } from "../../../../utils";
import { CENTS_IN_DOLLAR } from "../../../../constants";
import { ISalesPerCountry } from "../../../../api/billing/index.types";
import {
  ContainerWithLoader,
  PaginationWithLimit,
  SelectByDateWithCustomPeriod,
} from "../../../../components/ui";
import usePagination from "../../../../hooks/usePagination";

const SalesBreakdownPerCountry = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const pagination = usePagination("5");

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ISalesPerCountry[]>([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getReport = async (startDate: string, endDate: string, isAllTime?: boolean) => {
    setStartDate(startDate);
    setEndDate(endDate);

    try {
      setIsLoading(true);
      const query = new URLSearchParams();
      query.append("startDate", startDate);
      query.append("endDate", endDate);
      query.append("limit", pagination.limit);
      query.append("page", pagination.page.toString());

      if (isAllTime) {
        query.append("isAllTime", "show");
      }
      const response = await APIBilling.getSalesBreakdownPerCountryRequest(query.toString());

      pagination.setCount(response.data.report[0].rows);
      setData(response.data.report);
    } catch (error) {
      console.error("Error getting SalesBreakdownPerCountry", error);
    } finally {
      setIsLoading(false);
    }
  };

  const max = data.at(0)?.usage || 0;
  const handleChangePage = (page: number) => {
    pagination.setPage(page);
  };
  useEffect(() => {
    (async () => {
      if (startDate && endDate) {
        await getReport(startDate, endDate);
      }
    })();
  }, [pagination.page, pagination.limit]);

  useEffect(() => {
    pagination.setPage(0);
  }, [pagination.limit]);

  return (
    <div>
      <div>
        <SelectByDateWithCustomPeriod
          getReport={getReport}
          setIsLoading={setIsLoading}
          setReport={setData}
        />
      </div>
      <div className={generalClasses.containerBg}>
        <ContainerWithLoader isLoading={isLoading} report={data}>
          <div>
            <div className={classes.countriesContainer}>
              <div className={classes.locationRow}>
                <b>Location</b>
              </div>
              <div className={classes.locationRow}>
                <b>Sales</b>
              </div>
              <div className={classes.locationRow}>
                <b>$</b>
              </div>

              {data.map((d) => (
                <React.Fragment key={d.name}>
                  <div className={classes.locationRow}>
                    <div
                      style={{
                        width: `${Math.round((d.usage / max) * 100)}%`,
                      }}
                      className={classes.locationRowProgress}
                    />
                    {d.name}
                  </div>
                  <div className={classes.locationRow}>{d.usage}</div>
                  <div className={classes.locationRow}>
                    {IntlFormatUSD(d.sum_usd / CENTS_IN_DOLLAR)}
                  </div>
                </React.Fragment>
              ))}
            </div>

            <PaginationWithLimit
              count={pagination.count}
              limit={pagination.limit}
              setLimit={pagination.setLimit}
              page={pagination.page}
              setPageState={handleChangePage}
            />
          </div>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default SalesBreakdownPerCountry;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import { AgentsActions } from "../../../../reducers/agentsReducer";

import { APIAgents } from "../../../../api";
import { useAuthContext } from "../../../../context";

import usePagination from "../../../../hooks/usePagination";
import useStyles from "./useStyles";
import {
  ContainerWithLoader,
  PaginationWithLimit,
  TableDataCellWithSort,
} from "../../../../components/ui";
import { ISortBy } from "../../../../types";
import newSortBy from "../../../../utils/newSortBy";
import AllowedFeaturesToolTip from "../AllowedFeaturesToolTip";

type TBy = "name" | "email" | "preset" | "active";

const AgentsList = (): React.ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loginAndQuery, hasExpired } = useAuthContext();

  const [sortBy, setSortBy] = useState<ISortBy>({
    by: "active",
    order: false,
  });
  const { data, loading } = useSelector((state) => state.agents);
  const policesState = useSelector((state) => state.polices);
  const [search, setSearch] = useState<string>("");
  const { page, setPage, limit, setLimit } = usePagination();

  const handleOnClick = useCallback((id: number) => history.push(`/agent/${id}`), [history]);

  const getAgent = async () => {
    const dataRequest = { limit, page, contains: search, sortBy };
    dispatch(AgentsActions.fetchAgentsRequest());
    try {
      const response = await APIAgents.getAgentsRequest(dataRequest);
      dispatch(AgentsActions.fetchAgentsSuccess(response));
    } catch (error) {
      dispatch(AgentsActions.fetchAgentsFailed());
      console.error("error fetching agents list", error);
      hasExpired();
    }
  };

  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });

  useEffect(() => {
    loginAndQuery(getAgent);
  }, [page, search, limit, sortBy]);

  return (
    <div className="customers">
      <input
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search..."
      />

      <ContainerWithLoader isLoading={loading && policesState.loading} report={data.agents}>
        <table className={classes.table}>
          <thead>
            <tr>
              <td>IID</td>
              <TableDataCellWithSort
                by="name"
                title="Name"
                sortBy={sortBy}
                newSortBy={handleSortBy}
              />
              <TableDataCellWithSort
                by="email"
                title="Email"
                sortBy={sortBy}
                newSortBy={handleSortBy}
              />
              <td>Preset</td>
              <td>Prop Firm</td>
              <TableDataCellWithSort
                by="active"
                title="Status"
                sortBy={sortBy}
                newSortBy={handleSortBy}
              />
            </tr>
          </thead>

          <tbody>
            {data.agents.map((agent) => {
              if (loading) return null;
              return (
                <tr
                  onClick={() => handleOnClick(agent.id)}
                  key={agent.id}
                  className={cx({
                    [classes.activeAccount]: agent.active,
                    [classes.inactiveAccount]: !agent.active,
                  })}
                >
                  <td>{agent.id}</td>
                  <td>{agent.name}</td>
                  <td>{agent.email}</td>
                  <td>{agent.admin_accounts_policies_preset.name_preset}</td>
                  <td>
                    {data.agentsWithCustomPreset[agent.id] && (
                      <AllowedFeaturesToolTip
                        allowed_features={Array.from(
                          new Set(data.agentsWithCustomPreset[agent.id])
                        )}
                      />
                    )}
                  </td>
                  <td>{agent.active ? "Active" : "Inactive"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </ContainerWithLoader>

      <div>
        <PaginationWithLimit
          count={data.count}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPageState={setPage}
        />
      </div>
    </div>
  );
};

export default AgentsList;

import { EEvaluationType } from "../../../../constants";

export const getAccountTypeAndPropFirm = (
  accountName: string
): { accountType: string; propFirm: string } => {
  if (accountName.toLowerCase().includes("livesim")) {
    return {
      accountType: EEvaluationType.liveSim,
      propFirm: "Helios",
    };
  }
  if (accountName.toLowerCase().includes("7pv")) {
    return {
      accountType: EEvaluationType.live,
      propFirm: "Helios",
    };
  }
  return {
    accountType: EEvaluationType.live,
    propFirm: "Appius",
  };
};

import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import { ContainerWithLoader } from "../../../../components/ui";
import useGeneralStyles from "../../../../useGeneralStyles";
import useStyles from "../../useStyles";

const PaymentCards = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const { accounts_table, paymentCards } = useSelector((state) => state.complianceSearch);
  const baseClasses = useStyles();

  return (
    <div>
      <div className={cx(generalClasses.tableHorizontalScroll, baseClasses.relativeTable)}>
        <ContainerWithLoader
          report={paymentCards.data}
          isLoading={paymentCards.loading || accounts_table.loading}
        >
          <table
            className={cx(
              generalClasses.table,
              generalClasses.smTable,
              generalClasses.noTextWrap,
              generalClasses.tableWithoutHover
            )}
          >
            <TableHead />
            <TableBody />
          </table>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default PaymentCards;

import React, { useState } from "react";
import cx from "classnames";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";

import useGeneralStyles from "../../../../useGeneralStyles";
import useStyles from "./useStyles";
import { ServiceAPI } from "../../../../api/service";
import { IBitPayExchangeRates } from "../../../../api/service/index.types";
import { ContainerWithLoader } from "../../../../components/ui";
import { IntlFormatUSD } from "../../../../utils";

const BitPayExchangeRates = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const classes = useStyles();

  const [rates, setRates] = useState<IBitPayExchangeRates["data"]>({
    btc: { rate: 0 },
    eth: { rate: 0 },
  });

  const { isLoading } = useQuery({
    queryKey: ["get_exchange_rates"],
    queryFn: async () => {
      const response = await ServiceAPI.getBitPayExchangeRates();
      setRates(response.data);
      return response;
    },
    retry: 5,
    retryDelay: 10 * 1000,
  });

  return (
    <div>
      <div>
        <h2>BitPay Exchange Rates</h2>
      </div>
      <div className={cx(generalClasses.containerBg, classes.container)}>
        <ContainerWithLoader isLoading={isLoading} report={Object.keys(rates)}>
          <List>
            <ListItem>
              <ListItemText>1 ETH = {IntlFormatUSD(rates?.eth.rate)}</ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText>1 BTC = {IntlFormatUSD(rates?.btc.rate)}</ListItemText>
            </ListItem>
          </List>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default BitPayExchangeRates;

import React from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import copyToClipboardInXlsFormat, {
  IColum,
} from "../../../../../../utils/copyToClipboardInXlsFormat";

import useGeneralStyles from "../../../../../../useGeneralStyles";
const COLUMNS: Array<IColum> = [
  {
    by: "accounts_id",
    title: "Account ID ",
    path: "account.id",
  },
  {
    by: "email",
    title: "Email",
    path: "account.email",
  },
  {
    by: "name",
    title: "Name",
    path: "account.name",
  },
  {
    by: "id",
    title: "ID",
    path: "id",
  },
  {
    by: "plans_id",
    title: "Plans ID",
    path: "plans_id",
  },
  {
    by: "price_usd",
    title: "Price USD",
    path: "price_usd",
  },
  {
    by: "expires",
    title: "Expires",
    path: "expires",
  },
  {
    by: "falitures",
    title: "Failures",
    path: "falitures",
  },
  {
    by: "status",
    title: "Status",
    path: "status",
  },
  {
    by: "passed",
    title: "Passed",
    path: "passed",
    copyToClipBoardCallBack: (value) => (value ? "passed" : ""),
  },
  {
    by: "start",
    title: "Start",
    path: "start",
  },
  {
    by: "canceled",
    title: "Canceled",
    path: "canceled",
  },
  {
    by: "campaign_property_id",
    title: "Campaign property id",
    path: "campaign_property_id",
  },
  {
    by: "discount_code_id",
    title: "Discount code id",
    path: "discount_code_id",
  },
  {
    by: "upgrade",
    title: "Upgrade",
    path: "id",
    copyToClipBoardCallBack: (value) => (value ? "true" : "false"),
  },
  {
    by: "free_resets",
    title: "Free resets",
    path: "free_resets",
  },
  {
    by: "orig_discount_id",
    title: "Orig discount id",
    path: "orig_discount_id",
  },
];

const TableHead = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const { data } = useSelector((state) => state.complianceSearch.plansStatus);
  const handleCopyToClipBoard = () => copyToClipboardInXlsFormat(data, COLUMNS);

  return (
    <thead>
      <tr className={generalClasses.tableName}>
        <td colSpan={16}>
          <IconButton size="small" onClick={handleCopyToClipBoard}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </td>
      </tr>
      <tr>
        {COLUMNS.map((r) => (
          <td key={r.title}>{r.title}</td>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;

import httpClient from "../apiClient";
import * as Type from "./index.types";
import { TUpdateFundedTraderStatusRequest } from "./index.types";

export const FUNDS_API_URLS = {
  funds: "/funds",
  get_live_sim_accounts: "/funds/get_live_sim_accounts",
  get_documents_date: "/funds/get_documents_date",
  get_notes: "/funds/get_notes",
  add_note: "/funds/add_note",
  update_note: "/funds/update_note",
  update_business_ein_data: "/funds/update-business-ein",
  update_start_trading_date: "/funds/update-start-trading-date",
  update_trader: "/funds/update_trader",
  update_interview_status: "/funds/update-interview-status",
  update_affiliate_value: "/funds/update-affiliate-value",
  add_trader: "/funds/add_trader",
  get_traded_accounts: "/funds/get_traded_accounts",
  update_traded_accounts: "/funds/update_traded_accounts",
  add_traded_accounts: "/funds/add_traded_accounts",
  withdrawal_request: "/funds/withdrawal_request",
  edit_withdrawal_request: "/funds/edit_withdrawal_request",
  withdrawal_list: "/funds/withdrawal_list",
  withdrawal_change_status: "/funds/withdrawal_change_status",
  get_funded_trader_data: "/funds/get_funded_trader_data",
  set_watermark: "/funds/set_watermark",
  set_documents_date: "/funds/set_documents_date",
  get_ready_to_live_accounts: "/funds/get_ready_to_live_accounts",
  change_customer_sum_withdrawal: "/funds/change_customer_sum_withdrawal",
  updateFundedTraderStatus: "/funds/update-funded-trader",
  bunAccount: "/funds/ban-account",
  unBunAccount: "/funds/un-ban-account",
} as const;

export class FundsAPI {
  static sendTraderToFundsRequest: Type.TSendTraderToFundsRequest = (id, body) =>
    httpClient.post(`${FUNDS_API_URLS.add_trader}/${id}`, body);
  static updateFundedTraderDataRequest: Type.TUpdateFundedTraderDataRequest = (id, body) =>
    httpClient.post(`${FUNDS_API_URLS.update_trader}/${id}`, body);
  static getFundedTraders: Type.TGetFundedTraders = (data) =>
    httpClient.post(FUNDS_API_URLS.funds, data);
  static getTradedAccountsRequest: Type.TGetTradedAccountsRequest = (id) =>
    httpClient.post(`${FUNDS_API_URLS.get_traded_accounts}/${id}`);
  static getCappedSteppedAccountListRequest: Type.TGetCappedSteppedAccountListRequest = (
    body,
    signal
  ) => httpClient.post(FUNDS_API_URLS.get_live_sim_accounts, body, { signal });
  static updateAccountProcessingStatus: Type.TUpdateAccountProcessingStatus = (accounts_id, body) =>
    httpClient.post(`${FUNDS_API_URLS.update_traded_accounts}/${accounts_id}`, body);
  static setTradedAccountsRequest: Type.TSetTradedAccountsRequest = (id, body) =>
    httpClient.post(`${FUNDS_API_URLS.add_traded_accounts}/${id}`, body);
  static createWithdrawalRequest: Type.TCreateWithdrawalRequest = (body) =>
    httpClient.post(FUNDS_API_URLS.withdrawal_request, body);
  static editWithdrawalRequest: Type.TEditWithdrawalRequest = (body) =>
    httpClient.post(FUNDS_API_URLS.edit_withdrawal_request, body);
  static getWithdrawalsListRequest: Type.TGetWithdrawalsListRequest = (body, signal) =>
    httpClient.post(FUNDS_API_URLS.withdrawal_list, body, { signal });
  static updateWithdrawalStatus: Type.TUpdateWithdrawalStatus = (data) =>
    httpClient.post(FUNDS_API_URLS.withdrawal_change_status, data);
  static getFundedTraderDataRequest: Type.TGetFundedTraderDataRequest = (accounts_id) =>
    httpClient.post(`${FUNDS_API_URLS.get_funded_trader_data}/${accounts_id}`);
  static setFundedTraderWatermark: Type.TSetFundedTraderWatermark = (accounts_id, watermark) =>
    httpClient.post(`${FUNDS_API_URLS.set_watermark}/${accounts_id}`, { watermark });
  static setFundedAccountDocumentDatesRequest: Type.TSetFundedAccountDocumentDatesRequest = (
    funded_accounts_id,
    body
  ) => httpClient.post(`${FUNDS_API_URLS.set_documents_date}/${funded_accounts_id}`, body);
  static getFundedAccountDocumentDatesRequest: Type.TGetFundedAccountDocumentDatesRequest = (
    funded_accounts_id
  ) => httpClient.post(`${FUNDS_API_URLS.get_documents_date}/${funded_accounts_id}`);
  static getFundedAccountNotesRequest: Type.TGetFundedAccountNotesRequest = (
    funded_accounts_id,
    body
  ) => httpClient.post(`${FUNDS_API_URLS.get_notes}/${funded_accounts_id}`, body);
  static setFundedAccountNoteRequest: Type.TSetFundedAccountNoteRequest = (accounts_id, body) =>
    httpClient.post(`${FUNDS_API_URLS.add_note}/${accounts_id}`, body);
  static updateFundedAccountNoteRequest: Type.TUpdateFundedAccountNoteRequest = (note_id, body) =>
    httpClient.post(`${FUNDS_API_URLS.update_note}/${note_id}`, body);
  static updateBusinessEinDataRequest: Type.TUpdateBusinessEinDataRequest = (id, body) =>
    httpClient.post(`${FUNDS_API_URLS.update_business_ein_data}/${id}`, body);
  static updateStartTradingDate: Type.TUpdateStartTradingDate = (id, body) =>
    httpClient.post(`${FUNDS_API_URLS.update_start_trading_date}/${id}`, body);
  static updateInterviewStatusRequest: Type.TUpdateInterviewStatusRequest = (id, body) =>
    httpClient.post(`${FUNDS_API_URLS.update_interview_status}/${id}`, body);
  static updateAffiliateValueRequest: Type.TUpdateAffiliateValueRequest = (id, body) =>
    httpClient.post(`${FUNDS_API_URLS.update_affiliate_value}/${id}`, body);
  static getForcedToLiveAccountsRequest: Type.TGetForcedToLiveAccountsRequest = (body) =>
    httpClient.post(`${FUNDS_API_URLS.get_ready_to_live_accounts}`, body);
  static changeCustomerSumOfWithdrawals: Type.TChangeCustomerSumOfWidtdrawalsRequest = (
    accounts_id,
    body
  ) => httpClient.post(`${FUNDS_API_URLS.change_customer_sum_withdrawal}/${accounts_id}`, body);
  static updateFundedtraderStatusRequest: TUpdateFundedTraderStatusRequest = (body) =>
    httpClient.patch(FUNDS_API_URLS.updateFundedTraderStatus, body);
  static bunAccountRequest: Type.TBanAccountRequest = (accounts_id: number) =>
    httpClient.patch(`${FUNDS_API_URLS.bunAccount}/${accounts_id}`);
  static unBunAccountRequest: Type.TUnBanAccountRequest = (accounts_id: number): Promise<void> =>
    httpClient.patch(`${FUNDS_API_URLS.unBunAccount}/${accounts_id}`);
}

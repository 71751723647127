import React from "react";
import { useSelector } from "react-redux";

import { CENTS_IN_DOLLAR } from "../../../../../constants";
import { IntlFormatUSD } from "../../../../../utils";
import { CopyToClipboard } from "../../../../../components/ui";

const TableBody = (): React.ReactElement => {
  const { data } = useSelector((state) => state.complianceSearch.paymentCards);

  return (
    <tbody>
      {data.map((r) => {
        if (!r.payment_card) {
          return null;
        }
        return (
          <tr key={r.id}>
            <td>{r.payment_card.accounts_id}</td>
            <td>
              <CopyToClipboard value={r.payment_card.name} />
            </td>
            <td>
              <CopyToClipboard value={r.payment_card.brand} />
            </td>
            <td>{r.payment_card.last4}</td>
            <td>{r.payment_card.expires}</td>
            <td>{r.payment_card.partner}</td>
            <td>{IntlFormatUSD(r.price_usd / CENTS_IN_DOLLAR)}</td>
            <td>
              <CopyToClipboard value={r.payment_card.address} />
            </td>
            <td>
              <CopyToClipboard value={r.payment_card.city} />
            </td>
            <td>
              <CopyToClipboard value={r.payment_card.state} />
            </td>
            <td>{r.payment_card.country}</td>
            <td>{r.payment_card.zip}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import useGeneralStyles from "../../../../useGeneralStyles";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import { ContainerWithLoader } from "../../../../components/ui";
import useStyles from "../../useStyles";

const LogLoginsTable = (): React.ReactElement => {
  const baseClasses = useStyles();
  const generalClasses = useGeneralStyles();
  const { data, loading } = useSelector((state) => state.complianceSearch.logins);
  const accounts_table = useSelector((state) => state.complianceSearch.accounts_table);

  return (
    <div>
      <div className={cx(generalClasses.tableHorizontalScroll, baseClasses.relativeTable)}>
        <ContainerWithLoader report={data.rows} isLoading={loading || accounts_table.loading}>
          <table
            className={cx(
              generalClasses.table,
              generalClasses.smTable,
              generalClasses.noTextWrap,
              generalClasses.tableWithoutHover
            )}
          >
            <TableHead />
            <TableBody />
          </table>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default LogLoginsTable;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import { APIBilling } from "../../../../api";
import { ChartLine, ContainerWithLoader } from "../../../../components/ui";

import { SalesByPeriodPerCountry } from "../../../../api/billing/index.types";
import {
  get_plan_options,
  IPlanOption,
} from "../../../../components/ui/DiscountsModal/PlansOptions";
import { getDataSet, ICountriesToShowOption, IDataset, ITypePurchase } from "./utils";
import Filters from "./components/Filters";
import { BILLING_API_URLS } from "../../../../api/billing";

const SalesPerCountryLineChart = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SalesByPeriodPerCountry[]>([]);

  const [salesPerCountry, setSalesPerCountry] = useState<Record<string, number>>({});
  const [countriesToShow, setCountriesToShow] = useState<ICountriesToShowOption[]>([]);

  const [labels, setLabels] = useState<string[]>([]);
  const [datasets, setDatasets] = useState<IDataset[]>([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllTime, setIsAllTime] = useState(false);

  const [purchaseType, setPurchaseType] = useState<ITypePurchase>({
    isShowPurchase: true,
    isShowRebill: true,
    isShowResets: true,
    isShowFreeResets: true,
  });

  const {
    data: { plans },
    loading: isPlansLoading,
  } = useSelector((state) => state.plans);
  const plansOptions = get_plan_options(plans).filter(
    (p) => !["Select Plan", "Reset", "ALL - All plans", "Beginner Crash Course"].includes(p.label)
  );

  const [planToShow, setPlanToShow] = useState<IPlanOption[]>([]);

  const getReport = async (startDate: string, endDate: string, isAllTime?: boolean) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsAllTime(!!isAllTime);
  };

  useQuery({
    queryKey: [
      BILLING_API_URLS.salesByPeriodPerCountry,
      startDate,
      endDate,
      planToShow,
      isAllTime,
      ...Object.values(purchaseType),
    ],
    async queryFn({ signal }) {
      if (!planToShow.length) return;

      try {
        setIsLoading(true);
        const query = new URLSearchParams();
        query.append("startDate", startDate);
        query.append("endDate", endDate);
        query.append("planId", planToShow.map((p) => p.id).join(","));
        for (const [k, v] of Object.entries(purchaseType)) {
          query.append(k, v ? "show" : "");
        }
        if (isAllTime) {
          query.append("isAllTime", "show");
        }
        const response = await APIBilling.getSalesByPeriodPerCountryRequest(
          query.toString(),
          signal
        );
        setData(response.data.report);
        return response;
      } catch (error) {
        console.error("Error getting SalesPerCountryLineChart", error);
      } finally {
        setIsLoading(false);
      }
    },
    onError(error) {
      console.error("Error getting SalesPerCountryLineChart", error);
    },
  });

  useEffect(() => {
    if (!isPlansLoading) {
      setPlanToShow(plansOptions);
    }
  }, [isPlansLoading]);

  useEffect(() => {
    const labels: string[] = [];
    const salesPerCountry: Record<string, number> = {};

    data.forEach(({ date, name, count }) => {
      if (!labels.includes(date)) {
        labels.push(date);
      }
      if (salesPerCountry[name]) {
        salesPerCountry[name] = salesPerCountry[name] + count;
      } else {
        salesPerCountry[name] = count;
      }
    });

    const sortedSalesPerCountry = Object.entries(salesPerCountry).sort((a, b) => b[1] - a[1]);
    const countriesToShow = sortedSalesPerCountry.slice(0, 5).map(([c]) => ({
      value: c,
      label: c,
    }));

    setCountriesToShow(countriesToShow);
    setSalesPerCountry(salesPerCountry);
    setLabels(labels);
  }, [data]);

  useEffect(() => {
    setDatasets(getDataSet(data, countriesToShow, labels));
  }, [salesPerCountry, labels, countriesToShow]);

  return (
    <div>
      <h2>Sales by location</h2>

      <Filters
        getReport={getReport}
        setIsLoading={setIsLoading}
        setData={setData}
        setCountriesToShow={setCountriesToShow}
        setPlanToShow={setPlanToShow}
        planToShow={planToShow}
        plansOptions={plansOptions}
        countriesToShow={countriesToShow}
        salesPerCountry={salesPerCountry}
        purchaseType={purchaseType}
        setPurchaseType={setPurchaseType}
      />

      <ContainerWithLoader isLoading={isLoading || !plans.length} report={datasets}>
        <div>
          <ChartLine
            data={{
              labels,
              datasets,
            }}
            externalOptions={{
              elements: {
                line: {
                  borderWidth: 3,
                },
                point: {
                  radius: 2,
                },
              },
              plugins: {
                legend: {
                  align: "start",
                },
              },
            }}
          />
        </div>
      </ContainerWithLoader>
    </div>
  );
};

export default SalesPerCountryLineChart;

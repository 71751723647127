import React from "react";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSelector } from "react-redux";

import { useComplianceSearchContext } from "../../../../../context";
import TableDataCellWithSort from "../../../../../components/ui/TableDataCellWithSort";
import { TBy } from "../../../../../reducers/customersReducer";
import newSortBy from "../../../../../utils/newSortBy";
import useGeneralStyles from "../../../../../useGeneralStyles";
import { CENTS_IN_DOLLAR } from "../../../../../constants";
import { IntlFormatUSD } from "../../../../../utils";
import copyToClipboardInXlsFormat, {
  IColum,
} from "../../../../../utils/copyToClipboardInXlsFormat";

const COLUMNS: Array<IColum> = [
  {
    by: "accounts_id",
    title: "Account Id",
    path: "payment_card.accounts_id",
  },
  {
    by: "name",
    title: "Name on card",
    path: "payment_card.name",
  },
  {
    by: "brand",
    title: "Brand",
    path: "payment_card.brand",
  },
  {
    by: "last4",
    title: "Last4",
    path: "payment_card.last4",
  },
  {
    by: "expires",
    title: "Expires",
    path: "payment_card.expires",
  },
  {
    by: "partner",
    title: "Partner",
    path: "payment_card.partner",
  },
  {
    by: "price_usd",
    title: "Sales value",
    path: "price_usd",
    copyToClipBoardCallBack: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
  },
  {
    by: "address",
    title: "Adress",
    path: "payment_card.address",
  },
  {
    by: "city",
    title: "City",
    path: "payment_card.city",
  },
  {
    by: "state",
    title: "State",
    path: "payment_card.state",
  },
  {
    by: "country",
    title: "Country",
    path: "payment_card.country",
  },
  {
    by: "zip",
    title: "Zip",
    path: "payment_card.zip",
  },
];

const TableHead = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const {
    payment_cards_table: { sortBy, setSortBy },
  } = useComplianceSearchContext();
  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });

  const handleCopyToClipBoard = () => copyToClipboardInXlsFormat(data, COLUMNS);
  const { data } = useSelector((state) => state.complianceSearch.paymentCards);

  return (
    <thead>
      <tr className={generalClasses.tableName}>
        <td colSpan={12}>
          <IconButton size="small" onClick={handleCopyToClipBoard}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
          <span>Payment cards</span>
        </td>
      </tr>
      <tr>
        {COLUMNS.map((c) => (
          <TableDataCellWithSort
            key={c.by}
            sortBy={sortBy}
            by={c.by}
            title={c.title}
            newSortBy={handleSortBy}
          />
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;

import produce from "immer";

import {
  ComplianceSearchAccountsAction,
  ComplianceSearchAccountsActionType,
  IComplianceSearchAccountsState,
} from "./index.types";

export const initialState: IComplianceSearchAccountsState = {
  accounts_table: {
    data: {
      count: 0,
      rows: [],
    },
    loading: false,
  },
  logins: {
    data: {
      count: 0,
      rows: [],
    },
    loading: false,
  },
  uniqueReqIp: {
    data: [],
    loading: false,
  },
  paymentCards: {
    data: [],
    loading: false,
  },
  plans: {
    loading: false,
    data: {
      accounts: [],
      plans: [],
    },
  },
  plansStatus: {
    loading: false,
    data: [],
  },
  purchasesHistory: {
    data: [],
    loading: false,
  },
  resetsRebillsNewPurchases: {
    data: [],
    loading: false,
  },
  basicAccountDetailsAndSalesValue: {
    data: [],
    loading: false,
  },
  searchOnNameVariations: {
    data: {
      count: 0,
      rows: [],
    },
    loading: false,
  },
  searchOnPhoneVariations: {
    data: {
      count: 0,
      rows: [],
    },
    loading: false,
  },
};

const complianceSearchReducer = (
  prevState = initialState,
  action: ComplianceSearchAccountsAction
): IComplianceSearchAccountsState =>
  produce(prevState, (draft: IComplianceSearchAccountsState): IComplianceSearchAccountsState => {
    switch (action.type) {
      //SearchAccounts
      case ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsRequest:
        draft.accounts_table.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsSuccess:
        draft.accounts_table.data = action.payload;
        draft.accounts_table.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsFailed:
        draft.accounts_table.loading = false;
        break;
      //Search logins
      case ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsRequest:
        draft.logins.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsSuccess:
        draft.logins.data = action.payload;
        draft.logins.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsFailed:
        draft.logins.loading = false;
        break;
      // Search unique req ip
      case ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpRequest:
        draft.uniqueReqIp.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpSuccess:
        draft.uniqueReqIp.data = action.payload;
        draft.uniqueReqIp.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpFailed:
        draft.uniqueReqIp.loading = false;
        break;
      // SearchPaymentCards
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsRequest:
        draft.paymentCards.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsSuccess:
        draft.paymentCards.data = action.payload;
        draft.paymentCards.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsFailed:
        draft.paymentCards.loading = false;
        break;
      // SearchPlans
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansRequest:
        draft.plans.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansSuccess:
        draft.plans.data = action.payload;
        draft.plans.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansFailed:
        draft.plans.loading = false;
        break;
      // SearchPlansStatus
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusRequest:
        draft.plansStatus.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusSuccess:
        draft.plansStatus.data = action.payload;
        draft.plansStatus.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusFailed:
        draft.plansStatus.loading = false;
        break;
      // SearchPurchases
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesRequest:
        draft.purchasesHistory.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesSuccess:
        draft.purchasesHistory.data = action.payload;
        draft.purchasesHistory.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesFailed:
        draft.purchasesHistory.loading = false;
        break;
      // SearchResetsRebillsNewPurchases
      case ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesRequest:
        draft.resetsRebillsNewPurchases.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesSuccess:
        draft.resetsRebillsNewPurchases.data = action.payload;
        draft.resetsRebillsNewPurchases.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesFailed:
        draft.resetsRebillsNewPurchases.loading = false;
        break;
      // BasicAccountDetailsAndSalesValue
      case ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueRequest:
        draft.basicAccountDetailsAndSalesValue.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueSuccess:
        draft.basicAccountDetailsAndSalesValue.data = action.payload;
        draft.basicAccountDetailsAndSalesValue.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueFailed:
        draft.basicAccountDetailsAndSalesValue.loading = false;
        break;
      // AccountSearchOnNameVariations
      case ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsRequest:
        draft.searchOnNameVariations.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsSuccess:
        draft.searchOnNameVariations.data = action.payload;
        draft.searchOnNameVariations.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsFailed:
        draft.searchOnNameVariations.loading = false;
        break;
      // AllAccountSearchByPhone
      case ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneRequest:
        draft.searchOnPhoneVariations.loading = true;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneSuccess:
        draft.searchOnPhoneVariations.data = action.payload;
        draft.searchOnPhoneVariations.loading = false;
        break;
      case ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneFailed:
        draft.searchOnPhoneVariations.loading = false;
        break;
    }
    return draft;
  });

export default complianceSearchReducer;

import React from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import copyToClipboardInXlsFormat, {
  IColum,
} from "../../../../../../utils/copyToClipboardInXlsFormat";

import useGeneralStyles from "../../../../../../useGeneralStyles";
import { IntlFormatUSD } from "../../../../../../utils";
import { CENTS_IN_DOLLAR } from "../../../../../../constants";
const COLUMNS: Array<IColum> = [
  {
    by: "accounts_id",
    title: "Account id",
    path: "account.id",
  },
  {
    by: "email",
    title: "Email",
    path: "account.email",
  },
  {
    by: "name",
    title: "Name",
    path: "account.name",
  },
  {
    by: "transaction_id",
    title: "Transaction id",
    path: "id",
  },
  {
    by: "plan_type",
    title: "Plan type",
    path: "plan.name",
  },
  {
    by: "plan_id",
    title: "Plan id ",
    path: "plan.id",
  },
  {
    by: "price_usd",
    title: "Price usd",
    path: "price_usd",
    copyToClipBoardCallBack: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
  },
  {
    by: "status",
    title: "Status",
    path: "status",
  },
  {
    by: "stripe_ch",
    title: "Stripe ch",
    path: "stripe_ch",
  },
  {
    by: "purchase_date",
    title: "Purchase date",
    path: "purchase_date",
  },
  {
    by: "rebill",
    title: "Rebill",
    path: "rebill",
    copyToClipBoardCallBack: (value) => (value ? "true" : "false"),
  },
  {
    by: "reset",
    title: "Reset",
    path: "reset",
  },
  {
    by: "free_reset",
    title: "Free reset",
    path: "free_reset",
    copyToClipBoardCallBack: (value) => (value ? "true" : "false"),
  },
  {
    by: "discount_code_used",
    title: "Discount code used",
    path: "discount_code.code",
  },
  {
    by: "discount_code_description",
    title: "Discount code description",
    path: "discount_code.desc",
  },
  {
    by: "discount_type",
    title: "Discount type",
    path: "discount_code.type",
  },
  {
    by: "discount_amount",
    title: "Discount amount",
    path: "discount_code.amt",
  },
  {
    by: "not_recurring",
    title: "Not recurring",
    path: "discount_code.one_time",
    copyToClipBoardCallBack: (value) => (value ? "true" : "false"),
  },
  {
    by: "no_discount",
    title: "No discount",
    path: "discount_code",
    copyToClipBoardCallBack: (value) => (value ? "false" : "true"),
  },
];

const TableHead = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const { data } = useSelector((state) => state.complianceSearch.purchasesHistory);
  const handleCopyToClipBoard = () => copyToClipboardInXlsFormat(data, COLUMNS);

  return (
    <thead>
      <tr className={generalClasses.tableName}>
        <td colSpan={19}>
          <IconButton size="small" onClick={handleCopyToClipBoard}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </td>
      </tr>
      <tr>
        {COLUMNS.map((r) => (
          <td key={r.title}>{r.title}</td>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;

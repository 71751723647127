import React from "react";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";

import useStyles from "../../../useStyles";
import useGeneralStyles from "../../../../../useGeneralStyles";
import { getQueryStringByStringValues } from "../../../utils";

import { ComplianceAPI } from "../../../../../api/compliance";
import { ComplianceSearchActions } from "../../../../../reducers/ComplianceSearchReducer/actions";
import { ContainerWithLoader } from "../../../../../components/ui";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import SearchByListValues from "../../SearchByListValues";
import { EMAILS_LIST_VALIDATION_SCHEMA } from "../../../../../utils/validation";

const ResetsRebillsNewPurchases = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const dispatch = useDispatch();

  const { data, loading } = useSelector(
    (state) => state.complianceSearch.resetsRebillsNewPurchases
  );

  const fetchData = async (values: { emails: string }) => {
    try {
      dispatch(ComplianceSearchActions.fetchComplianceSearchResetsRebillsNewPurchasesRequest());

      const response = await ComplianceAPI.getAccountsResetsRebillsNewPurchases(
        getQueryStringByStringValues("emails", values.emails)
      );

      dispatch(
        ComplianceSearchActions.fetchComplianceSearchResetsRebillsNewPurchasesSuccess(
          response.data.accounts
        )
      );
    } catch (error) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchResetsRebillsNewPurchasesFailed());
      console.log("error fetching ResetsRebillsNewPurchases data", error);
    }
  };
  return (
    <div className={classes.container}>
      <b className={classes.title}>Search resets, rebills, new purchases</b>
      <SearchByListValues
        placeholder="john@gmail.com, doe@gmail.com e.t.c ..."
        label="Emails"
        name="emails"
        validationSchema={EMAILS_LIST_VALIDATION_SCHEMA}
        isLoading={loading}
        fetchData={fetchData}
        initialValues={{ emails: "" }}
      />

      <div className={classes.tableContainer}>
        <ContainerWithLoader isLoading={loading} report={data}>
          <div className={generalClasses.tableHorizontalScroll}>
            <table
              className={cx(
                generalClasses.table,
                generalClasses.smTable,
                generalClasses.tableWithoutHover,
                generalClasses.noTextWrap
              )}
            >
              <TableHead />
              <TableBody />
            </table>
          </div>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default ResetsRebillsNewPurchases;

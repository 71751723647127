import { ISortBy } from "../types";

interface INewSortBy {
  by: string;
  sortBy: ISortBy;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSortBy: (a: ISortBy<any>) => void;
}

const newSortBy = ({ by, sortBy, setSortBy }: INewSortBy): void => {
  if (by === "id" && sortBy.order) {
    setSortBy({ by, order: false });
    return;
  }
  if (sortBy.by === by) {
    setSortBy({ ...sortBy, order: !sortBy.order });
  } else {
    setSortBy({ by, order: true });
  }
};

export default newSortBy;

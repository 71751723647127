import React from "react";
import { useSelector } from "react-redux";
import { IntlFormatUSD } from "../../../../../../utils";
import { CENTS_IN_DOLLAR } from "../../../../../../constants";

const TableBody = (): React.ReactElement => {
  const { data } = useSelector((state) => state.complianceSearch.basicAccountDetailsAndSalesValue);
  return (
    <tbody>
      {data.map((r) => (
        <tr key={r.id}>
          <td>{r.id}</td>
          <td>{IntlFormatUSD(r.total_spent / CENTS_IN_DOLLAR)}</td>
          <td>{r.name}</td>
          <td>{r.email}</td>
          <td>{r.reg_ip}</td>
          <td>{r.phone}</td>
          <td>{r.address}</td>
          <td>{r.address2}</td>
          <td>{r.city}</td>
          <td>{r.stateprov}</td>
          <td>{r.country}</td>
          <td>{r.joined}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;

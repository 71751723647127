import lodash from "lodash";
import copy from "copy-to-clipboard";
import { IAccountBillingData, IAccountPlanData } from "../api/compliance/index.types";

export interface IColum {
  by: string;
  title: string;
  path: string;
  copyToClipBoardCallBack?: <T>(value: T) => string;
}

const getHeaders = (columns: IColum[]): string[] =>
  columns.filter((col) => !!col.title).map((c) => c.title);

const getRowData = <D,>(columns: IColum[], r: D) =>
  columns
    .filter((col) => !!col.title)
    .map((c) => {
      if (c.copyToClipBoardCallBack) {
        return c.copyToClipBoardCallBack(lodash.get(r, c.path));
      }
      return lodash.get(r, c.path);
    });

const getXls = (rows: string[][]) => rows.map((lines) => lines.join("\t")).join("\n");

const copyToClipboardInXlsFormat = <D,>(data: D[], columns: IColum[]): void => {
  const headers = getHeaders(columns);

  const rows: string[][] = [headers];

  data.forEach((r) => {
    const row = getRowData(columns, r);
    rows.push(row);
  });

  copy(getXls(rows), { format: "text/plain" });
};

export const copyToClipboardInXlsFormatWithNestedTAble = (
  parentData: IAccountBillingData[],
  parentColumns: IColum[],
  childData: IAccountPlanData[],
  childColumns: IColum[]
): void => {
  const parentHeaders = getHeaders(parentColumns);
  const childHeaders = getHeaders(childColumns);

  const rows: string[][] = [parentHeaders];

  parentData.forEach((parentRow) => {
    const parentRowData = getRowData(parentColumns, parentRow); // parentColumns
    rows.push(parentRowData);

    const currentAccountPlans = childData.filter((plan) => {
      return plan.accounts_id === parentRow.a_id;
    });
    if (currentAccountPlans.length) {
      rows.push(childHeaders);
      currentAccountPlans.forEach((childRow) => {
        const childRowData = getRowData(childColumns, childRow); // childColumns
        rows.push(childRowData);
      });
    }
  });

  copy(getXls(rows), { format: "text/plain" });
};

export default copyToClipboardInXlsFormat;

import * as React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import TableHead, { COLUMNS } from "./TableHead";
import TableBody from "./TableBody";

import {
  FilterAutocomplete,
  PaginationWithLimit,
  ContainerWithLoader,
} from "../../../../components/ui";
import { useComplianceSearchContext } from "../../../../context";

import useStyles from "./useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";
import copyToClipboardInXlsFormat from "../../../../utils/copyToClipboardInXlsFormat";
import { getSelectedRowsData } from "../../utils";

const AccountsTable = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const { accounts_table } = useComplianceSearchContext();

  const { data, loading } = useSelector((state) => state.complianceSearch.accounts_table);

  const handleCopyToClipBoard = () =>
    copyToClipboardInXlsFormat(
      accounts_table.selectedRows.length
        ? getSelectedRowsData(accounts_table.selectedRows, data.rows)
        : data.rows,
      COLUMNS
    );

  return (
    <div>
      <FilterAutocomplete
        search={accounts_table.search}
        setSearch={accounts_table.setSearch}
        filters={{
          "Account ID": "id",
          Email: "email",
          Name: "name",
          RegIp: "reg_ip",
          Phone: "phone",
          Country: "country",
          Zip: "zip",
          City: "city",
          Address: "address",
          Joined: "joined",
          "Name on card": "payment_card_name",
          "Card address": "payment_card_address",
        }}
        inputTypes={{
          Joined: "date",
        }}
      />

      <div className={cx(generalClasses.tableHorizontalScroll, classes.tableContainer)}>
        <div>
          <IconButton size="small" onClick={handleCopyToClipBoard}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
          <span>
            Users found: <b>{data.count}. </b>
            {!!accounts_table.selectedRows.length && (
              <>
                Selected: <b>{accounts_table.selectedRows.length}</b>
              </>
            )}
          </span>
        </div>
        <ContainerWithLoader isLoading={loading} report={data.rows}>
          <div className={classes.scrollContainer}>
            <table
              className={cx(
                generalClasses.table,
                generalClasses.smTable,
                generalClasses.noTextWrap,
                generalClasses.tableWithoutHover
              )}
            >
              <TableHead />
              <TableBody />
            </table>
          </div>

          <PaginationWithLimit
            limit={accounts_table.pagination.limit}
            setLimit={accounts_table.pagination.setLimit}
            page={accounts_table.pagination.page}
            setPageState={accounts_table.pagination.setPage}
            count={data.count}
          />
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default AccountsTable;

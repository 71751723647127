import React, { useState } from "react";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ClassNameMap } from "@material-ui/styles/withStyles";
import copy from "copy-to-clipboard";

interface IProps {
  value: string | number;
  buttonStyles?: ClassNameMap;
}

const useStyles = makeStyles({
  buttonRoot: {
    padding: "0 3px",
    textTransform: "none",
    fontWeight: 400,
    fontSize: "15px",
    minWidth: "auto",
    lineHeight: "13px",
  },
});
const CopyToClipboard = ({ value, buttonStyles }: IProps): React.ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipCpyClose = () => setOpen(false);
  const handleTooltipCopyOpen = () => {
    setOpen(true);
    copy(value.toString());
  };

  return (
    <div style={{ position: "relative" }} onMouseLeave={handleTooltipCpyClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={open}
        title="Copied"
      >
        <Button
          onClick={handleTooltipCopyOpen}
          classes={{ root: classes.buttonRoot, ...buttonStyles }}
        >
          {value}
        </Button>
      </Tooltip>
    </div>
  );
};

export default CopyToClipboard;

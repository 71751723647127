import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import useGeneralStyles from "../../../../useGeneralStyles";
import { ContainerWithLoader } from "../../../../components/ui";
import useStyles from "../../useStyles";

const UniqueRegistrationIpTable = (): React.ReactElement => {
  const baseClasses = useStyles();
  const generalClasses = useGeneralStyles();
  const { data, loading } = useSelector((state) => state.complianceSearch.uniqueReqIp);
  const accounts_table = useSelector((state) => state.complianceSearch.accounts_table);

  return (
    <div className={cx(generalClasses.tableHorizontalScroll, baseClasses.relativeTable)}>
      <ContainerWithLoader report={data} isLoading={accounts_table.loading || loading}>
        <table
          className={cx(
            generalClasses.table,
            generalClasses.smTable,
            generalClasses.noTextWrap,
            generalClasses.tableWithoutHover
          )}
        >
          <TableHead />
          <TableBody />
        </table>
      </ContainerWithLoader>
    </div>
  );
};

export default UniqueRegistrationIpTable;

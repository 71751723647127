import { makeStyles } from "@material-ui/core/";
import { STATUSES_COLORS } from "../../constants";

const useStyles = makeStyles({
  leadAccount: {
    backgroundColor: STATUSES_COLORS.lead.color,
  },
  canceled: {
    backgroundColor: STATUSES_COLORS.inactive.color,
  },
  inactiveAccount: {
    backgroundColor: STATUSES_COLORS.inactive.color,
  },
  activeAccount: {
    backgroundColor: STATUSES_COLORS.active.color,
  },
  passedAccount: {
    backgroundColor: STATUSES_COLORS.passed.color,
  },

  notes: {
    whiteSpace: "pre-line",
  },
});

export default useStyles;

import React from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "../../useStyles";
import useGeneralStyles from "../../../../useGeneralStyles";
import { ComplianceAPI } from "../../../../api/compliance";
import { ContainerWithLoader } from "../../../../components/ui";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import SearchByListValues from "../SearchByListValues";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { getQueryStringByStringValues } from "../../utils";
import { EMAILS_LIST_VALIDATION_SCHEMA } from "../../../../utils/validation";

const SearchAccountsPlans = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const {
    data: { accounts },
    loading,
  } = useSelector((state) => state.complianceSearch.plans);

  const fetchData = async (values: { emails: string }) => {
    try {
      dispatch(ComplianceSearchActions.fetchComplianceSearchPlansRequest());
      const response = await ComplianceAPI.getAccountPlans(
        getQueryStringByStringValues("emails", values.emails)
      );
      dispatch(ComplianceSearchActions.fetchComplianceSearchPlansSuccess(response.data));
    } catch (error) {
      console.log("error SearchAccountsPlans fetching data", error);
      dispatch(ComplianceSearchActions.fetchComplianceSearchPlansFailed());
    }
  };

  return (
    <div>
      <SearchByListValues
        validationSchema={EMAILS_LIST_VALIDATION_SCHEMA}
        isLoading={loading}
        fetchData={fetchData}
        placeholder="john@gmail.com, doe@gmail.com e.t.c ..."
        label="Emails"
        name="emails"
        initialValues={{ emails: "" }}
      />

      <div className={classes.tableContainer}>
        <ContainerWithLoader isLoading={loading} report={accounts}>
          <div className={generalClasses.tableHorizontalScroll}>
            <table
              className={cx(
                generalClasses.table,
                generalClasses.smTable,
                generalClasses.tableWithoutHover,
                generalClasses.noTextWrap
              )}
            >
              <TableHead />
              <TableBody />
            </table>
          </div>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default SearchAccountsPlans;

import React, { useReducer } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress as MUIProgress,
} from "@material-ui/core";

import useStyles from "./useStyles";
import { ACCOUNTS_API_URLS } from "../../../../api/accounts";
import { CircularProgress, Snackbar } from "../../../../components/ui";
import { useAdminProviderDataContext, useCustomerProvider } from "../../../../context";
import { AGENT_ACTIVITY_LOG_API_URLS } from "../../../../api/agentActivityLog";
import { EVALUATIONS_API_URLS, EvaluationsAPI } from "../../../../api/evaluations";
import stateReducer, { initialState } from "./stateReducer";
import DialogActionsButtons from "./components/DialogActionsButtons";
import DialogConfirmFundedOrBannedData from "./components/DialogConfirmFundedOrBannedData";

const DTitle = () => <DialogTitle>Update subscription status</DialogTitle>;
const DialogContentQuestion = () => (
  <DialogContentText>
    Would you like to update this subscription status to <b>passed</b>?
  </DialogContentText>
);

const CompleteEvaluation = (): React.ReactElement => {
  const { id } = useParams();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const { customer, getCustomer } = useCustomerProvider();

  const {
    plans: {
      data: { activePlan },
    },
    mainData: {
      loading: isLoadingMainData,
      data: { funded_trader, banned_account },
    },
    managementData: {
      data: { status: account_status },
    },
  } = useSelector((state) => state.customer);
  const { plans } = useSelector((state) => state.plans.data);

  const isAvailablePlanForPassing =
    plans.findIndex(
      (plan) => plan.id === activePlan?.plans_id && plan.is_available_for_evaluation_complete
    ) !== -1;
  const isAllowedToCompleteEvaluation =
    allowed_features.complete_evaluation || allowed_features.access_all_features;
  const isInactiveAccount = account_status === 0;

  const handleCancel = () => dispatch({ type: "closeDialog" });
  const handleClickOpen = async () => {
    await getCustomer();
    dispatch({ type: "openDialog" });
  };

  const handleCompleteEvaluation = useMutation({
    mutationKey: [EVALUATIONS_API_URLS.evaluationComplete, id],
    mutationFn: async () => {
      return await EvaluationsAPI.evaluationCompleteRequest(id, {
        intercom_id: customer.intercom?.id as string,
        e2t_account_id: customer.intercom?.custom_attributes?.["Gauntlet Account Name"],
        email: customer.email,
        type_and_size: activePlan?.plan.key as string,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.accountPlans],
      });
      queryClient.invalidateQueries({
        queryKey: [AGENT_ACTIVITY_LOG_API_URLS.getCustomerLogs, id],
      });
      dispatch({
        type: "onSuccessResponse",
        payload: { responseSuccessMessage: "Evaluation completed successfully" },
      });
    },
    onError: (error: {
      response: {
        data: {
          intercom: {
            statusText: string;
          };
        };
      };
    }) => {
      const isIntercomError = error?.response?.data?.intercom;
      const intercomErrorText = "Error completing evaluation. Intercom error:";

      dispatch({
        type: "onErrorResponse",
        payload: {
          responseErrorMessage: isIntercomError
            ? `${intercomErrorText} ${error.response.data.intercom.statusText}`
            : "Error completing evaluation",
        },
      });
    },
    onSettled: () => {
      setTimeout(() => {
        dispatch({ type: "onSettled" });
      }, 1500);
    },
  });

  const handleConfirm = () => {
    if ((funded_trader?.ban_date || banned_account?.timestamp) && !state.openConfirmation) {
      dispatch({ type: "openConfirmationForBannedAccounts" });
    } else {
      handleCompleteEvaluation.mutate();
    }
  };

  const handleCloseSnackbar = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    dispatch({ type: "onSettled" });
  };

  const isDisabledActionButton = isLoadingMainData || handleCompleteEvaluation.isLoading;
  const isDisabledEvaluationCompletedButton =
    !isAllowedToCompleteEvaluation ||
    !isAvailablePlanForPassing ||
    isInactiveAccount ||
    isLoadingMainData;

  return (
    <div>
      <Dialog open={state.open} onClose={handleCancel}>
        <Dialog open={state.openConfirmation}>
          <DTitle />

          <DialogContent dividers>
            <DialogContentQuestion />

            <DialogConfirmFundedOrBannedData
              funded_trader_ban_date={funded_trader?.ban_date}
              banned_account_timestamp={banned_account?.timestamp}
            />

            <DialogContentText>
              <b>Please consult with management and compliance before proceeding.</b>
            </DialogContentText>
          </DialogContent>

          <DialogActionsButtons
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            isDisabledActionButton={isDisabledActionButton}
          />
        </Dialog>

        <DTitle />
        <DialogContent dividers>
          <CircularProgress isLoading={isLoadingMainData} />
          <DialogContentText>
            {funded_trader && (
              <b>
                Trader already funded with status:{" "}
                {funded_trader?.funded_trader_status?.status_name}
              </b>
            )}
          </DialogContentText>
          <DialogContentQuestion />
        </DialogContent>
        <DialogActionsButtons
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          isDisabledActionButton={isDisabledActionButton}
        />
      </Dialog>

      <Button
        className={classes.evaluationCompleteButton}
        size="small"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        disabled={isDisabledEvaluationCompletedButton}
        endIcon={isLoadingMainData && <MUIProgress size={20} />}
      >
        Evaluation Completed
      </Button>

      <Snackbar
        type={state.responseSuccessMessage ? "success" : "error"}
        isOpenSnackbar={state.openSnackbar}
        responseSuccessMessage={state.responseSuccessMessage as string}
        responseErrorMessage={state.responseErrorMessage as string}
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default CompleteEvaluation;

import React from "react";

import SubscriptionStatusTable from "./SubscriptionStatusTable";
import AllPurchases from "./AllPurchases";
import ResetsRebillsNewPurchases from "./ResetsRebillsNewPurchases";
import BasicAccountDetailsAndSalesValue from "./BasicAccountDetailsAndSalesValue";

const QuickSearch = (): React.ReactElement => {
  return (
    <div>
      <BasicAccountDetailsAndSalesValue />
      <SubscriptionStatusTable />
      <AllPurchases />
      <ResetsRebillsNewPurchases />
    </div>
  );
};

export default QuickSearch;

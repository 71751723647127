import React from "react";
import { isEqual } from "lodash";

import { MainInfoValue } from "../../../../components/ui";
import { useCustomerProvider } from "../../../../context";
import { getGauntletAccountName } from "./utils";

const IntercomData: React.FC = () => {
  const { customer, loading } = useCustomerProvider();

  if (loading.intercom === 0) {
    return (
      <MainInfoValue title="Intercom Data">
        <>in queue...</>
      </MainInfoValue>
    );
  }
  if (loading.intercom === 1) {
    return (
      <MainInfoValue title="Intercom Data">
        <>loading...</>
      </MainInfoValue>
    );
  }
  if (
    (loading.intercom === 2 && customer.intercom === null) ||
    (loading.intercom === 2 && isEqual(customer.intercom, {}))
  ) {
    return (
      <div style={{ display: "inline-block" }}>
        <MainInfoValue title="CME Status">
          <>
            {customer.account_agreements.length > 0 ? customer.account_agreements[0].status : "N/A"}
          </>
        </MainInfoValue>

        <MainInfoValue title="Intercom Data">
          <>no intercom data found</>
        </MainInfoValue>
      </div>
    );
  }
  if (loading.intercom === 2 && customer.intercom !== null && !isEqual(customer.intercom, {})) {
    const browser_version = customer.intercom?.browser_version;

    return (
      <div style={{ display: "inline-block" }}>
        <MainInfoValue title="CME Status">
          <>
            {customer.account_agreements.length > 0 ? customer.account_agreements[0].status : "N/A"}
          </>
        </MainInfoValue>

        <MainInfoValue title="E2T Account ID">
          <>{getGauntletAccountName(customer)}</>
        </MainInfoValue>
        <MainInfoValue title="Gauntlet Attempt(s)">
          <>{customer.intercom?.custom_attributes["Gauntlet Attempt"]}</>
        </MainInfoValue>
        <MainInfoValue title="Unsubscribed from Emails">
          <>{customer.intercom?.unsubscribed_from_emails ? "Yes" : "No"}</>
        </MainInfoValue>
        <MainInfoValue title="Platform">
          <>
            {`OS: ${customer.intercom?.os ?? "n/a"}, Browser: ${
              customer.intercom?.browser ?? "n/a"
            }`}
            <br />
            {`Ver: ${browser_version ?? "n/a"}, Lang: ${
              customer.intercom?.browser_language ?? "n/a"
            }`}
          </>
        </MainInfoValue>
      </div>
    );
  }
  return (
    <MainInfoValue title="Intercom Data">
      <>error loading data!</>
    </MainInfoValue>
  );
};

export default IntercomData;

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    fontWeight: "bold",
    fontSize: "16px",
  },
  valueContainer: {
    display: "flex",
    alignItems: "baseline",
  },
  container: {
    display: "flex",
    whiteSpace: "break-spaces",
    alignItems: "center",
  },
});

interface IProps {
  title: string;
  children?: React.ReactElement;
}
const MainInfoValue = ({ title, children }: IProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="subtitle2">
        {title}:&nbsp;
      </Typography>
      {children}
    </div>
  );
};

export default MainInfoValue;

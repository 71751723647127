import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/";
import { CopyToClipboard } from "../../../../../components/ui";

const useStyles = makeStyles({
  textCenter: {
    textAlign: "center",
  },
});

const TableBody = (): React.ReactElement => {
  const classes = useStyles();
  const { data } = useSelector((state) => state.complianceSearch.uniqueReqIp);
  return (
    <tbody>
      {data.map((r) => (
        <tr key={r.reg_ip}>
          <td>
            <CopyToClipboard value={r.reg_ip} />
          </td>
          <td className={classes.textCenter}>{r.used_times}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;

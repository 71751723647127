import { IEntriesData } from "../../api/compliance/index.types";

export const getQueryStringByStringValues = (by: string, values: string): string => {
  const arrayValues = values.trim().split(/[\s,]+/);
  const query = new URLSearchParams();
  arrayValues.forEach((val) => {
    query.append(by, val);
  });

  return query.toString();
};

export const getSelectedRowsData = (
  selectedRows: Record<"id", number>[],
  data: IEntriesData[]
): IEntriesData[] => {
  return data.filter((r) => selectedRows.find((selected) => r.id === selected.id));
};

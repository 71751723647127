import React, { Fragment } from "react";
import { Grid } from "@material-ui/core/";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import moment from "moment-timezone";
import { useQueryClient } from "@tanstack/react-query";

import {
  ContainerWithLoader,
  ConfirmDialog,
  FormSelect,
  MainInfoValue,
} from "../../../../../../components/ui";
import { ACTIVE_OPTIONS, STATUS_OPTIONS } from "../../utils";

import { APIAccountsSimulators } from "../../../../../../api";
import { IAccountGauntlets } from "../../../../../../api/accounts/index.types";
import { DATE_FORMAT_hhmmAzz } from "../../../../../../constants";
import { ACCOUNTS_API_URLS } from "../../../../../../api/accounts";

type THandleSubmitForm = (
  accounts_id: number,
  data: { active: boolean; status: IAccountGauntlets["status"] }
) => Promise<void>;
const AccountSimulators = (): React.ReactElement => {
  const queryClient = useQueryClient();
  const { id: accounts_id } = useParams();
  const {
    loading,
    data: { account_simulators },
  } = useSelector((state) => state.customer.managementData);

  const { banned_account } = useSelector((state) => state.customer.mainData.data);
  const isDisabledByBunReason = !!banned_account?.field_values_before_bun?.account_simulators_id;

  const handleSubmitForm: THandleSubmitForm = async (id, { active, status }) => {
    try {
      if (!active) {
        await APIAccountsSimulators.updateAccountSimulatorRequest(id, {
          active,
          status: "canceled",
          accounts_id,
        });
      } else {
        await APIAccountsSimulators.updateAccountSimulatorRequest(id, {
          active,
          status,
          accounts_id,
        });
      }
    } catch (error) {
      console.error("Error updating gauntlet account", error);
    } finally {
      await queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.getAccountManagementData, accounts_id],
      });
    }
  };

  return (
    <div>
      <h4>Account simulator</h4>
      <ContainerWithLoader
        isLoading={loading}
        report={account_simulators}
        noResultsMessage="There isn't active account simulator"
      >
        {account_simulators.map((account) => (
          <Fragment key={account.id}>
            <Formik
              key={account.id}
              enableReinitialize
              initialValues={{
                active: account.active,
                status: account.status,
              }}
              onSubmit={(values) => handleSubmitForm(account.id, values)}
            >
              {({ dirty, handleReset, handleSubmit }) => (
                <Form>
                  <Grid container spacing={3}>
                    <ConfirmDialog
                      isOpen={dirty}
                      dialogTitle="Edit Account simulator"
                      dialogContentText="Are you sure you want to edit account simulator?"
                      handleClickOk={() => handleSubmit()}
                      handleCancel={() => handleReset()}
                    />
                    <Grid item xs={3}>
                      <Field
                        name="active"
                        label="Active"
                        placeholder="Active"
                        size="small"
                        options={ACTIVE_OPTIONS}
                        component={FormSelect}
                        disabled={isDisabledByBunReason}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="status"
                        label="Status"
                        placeholder="Status"
                        size="small"
                        options={STATUS_OPTIONS}
                        component={FormSelect}
                        disabled={isDisabledByBunReason}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MainInfoValue title="Exchanges">
                        <>{account.exchanges.Exchanges.join(", ")}</>
                      </MainInfoValue>
                      <MainInfoValue title="Commission">
                        <>{account.commission || "N/A"}</>
                      </MainInfoValue>
                      <MainInfoValue title="Request time">
                        <>
                          {moment(account.timestamp)
                            .tz("America/Chicago")
                            .format(DATE_FORMAT_hhmmAzz)}
                        </>
                      </MainInfoValue>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Fragment>
        ))}
      </ContainerWithLoader>
    </div>
  );
};

export default AccountSimulators;

import React from "react";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import copyToClipboardInXlsFormat from "../../../../../../../utils/copyToClipboardInXlsFormat";
import { IGetAllEntriesRequestByValuesResponse } from "../../../../../../../api/compliance/index.types";

const COLUMNS = [
  {
    by: "id",
    title: "Account ID",
    path: "id",
  },
  {
    by: "name",
    title: "Name",
    path: "name",
  },
  {
    by: "email",
    title: "Email",
    path: "email",
  },
  {
    by: "phone",
    title: "Phone",
    path: "phone",
  },
];

interface IProps {
  data: IGetAllEntriesRequestByValuesResponse["data"]["rows"];
}

const TableHead: React.FC<IProps> = ({ data }) => {
  const handleCopyToClipBoard = () => copyToClipboardInXlsFormat(data, COLUMNS);

  return (
    <thead>
      <tr>
        <td colSpan={4}>
          <IconButton size="small" onClick={handleCopyToClipBoard}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </td>
      </tr>
      <tr>
        {COLUMNS.map((r) => (
          <td key={r.by}>{r.title}</td>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;

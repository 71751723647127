import React from "react";
import * as Yup from "yup";
import { INewTradedAccountForm } from "./components/TradedAccounts";
import { FundedTraderActions } from "../../reducers/fundedTraderReducer/actions";
import { FundedTraderAction } from "../../reducers/fundedTraderReducer/index.types";
import { APIFunds } from "../../api";
import { EPProcessingCompletedStatuses, EProcessingStatuses } from "../../constants";
import { ITradedAccount } from "../../api/funds/index.types";
import { ISelectOption } from "../../components/ui/FormSelect";
export const ACCOUNTS_SCHEMA = Yup.object().shape({
  e2t_account_id: Yup.string().required("Account ID is required").min(3),
  processing_status: Yup.string().required("Processing status is required"),
  invoice_status: Yup.string().required("Invoice status is required"),
  evaluation_completed_history_id: Yup.number()
    .min(1, "E2T account name is required")
    .required("E2T account name is required"),
});

export const getAccountsInitialValues = (customerId: number): INewTradedAccountForm => ({
  e2t_account_id: "",
  prop_firm: "",
  account_type: "",
  processing_status: "",
  accounts_id: customerId,
  funded_traders_id: customerId,
  initial_tcp: "",
  invoice_status: "",
  evaluation_completed_history_id: 0,
});

export const fetchTradedAccounts = async (
  dispatch: React.Dispatch<FundedTraderAction>,
  customerId: number
): Promise<void> => {
  try {
    dispatch(FundedTraderActions.fetchFundedTraderAccountsRequest());
    const response = await APIFunds.getTradedAccountsRequest(customerId);
    dispatch(FundedTraderActions.fetchFundedTraderAccountsSuccess(response));
  } catch (error) {
    console.error("Error getting traded accounts", error);
  } finally {
    dispatch(FundedTraderActions.fetchFundedTraderAccountsFailed());
  }
};

export const checkIsCompletedAccount = (processing_status: EProcessingStatuses): boolean =>
  EPProcessingCompletedStatuses.includes(processing_status);

export const getTradedAccountsOptions = (tradedAccountsData: ITradedAccount[]): ISelectOption[] => {
  const options = [
    {
      value: undefined,
      label: "No",
      id: -1,
    },
  ];
  const tradedAccounts = tradedAccountsData
    .filter(
      ({ initial_tcp, processing_status }) =>
        !!initial_tcp && processing_status !== EProcessingStatuses.failed
    )
    .map((account) => ({
      value: account.id,
      label: account.e2t_account_id,
      id: account.id,
    }));

  return [...options, ...tradedAccounts];
};

export const MAXIMUM_FUNDED_ACCOUNTS_COUNT = 3;

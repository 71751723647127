import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import useStyles from "../../../useStyles";
import useGeneralStyles from "../../../../../useGeneralStyles";

import SearchByListValues from "../../SearchByListValues";
import { ComplianceSearchActions } from "../../../../../reducers/ComplianceSearchReducer/actions";
import { ComplianceAPI } from "../../../../../api/compliance";
import { getQueryStringByStringValues } from "../../../utils";
import { ContainerWithLoader } from "../../../../../components/ui";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import { EMAILS_LIST_VALIDATION_SCHEMA } from "../../../../../utils/validation";

const BasicAccountDetailsAndSalesValue = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const { loading, data } = useSelector(
    (state) => state.complianceSearch.basicAccountDetailsAndSalesValue
  );

  const fetchData = async (values: { emails: string }) => {
    try {
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchBasicAccountDetailsAndSalesValueRequest()
      );
      const response = await ComplianceAPI.getBasicAccountDetailsAndSalesValue(
        getQueryStringByStringValues("emails", values.emails)
      );
      console.log("response", response);
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchBasicAccountDetailsAndSalesValueSuccess(
          response.data.accounts
        )
      );
    } catch (error) {
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchBasicAccountDetailsAndSalesValueFailed()
      );
      console.log("Error fetching basic account details and sales value data", error);
    }
  };

  return (
    <div>
      <b className={classes.title}>Basic Account Details And Sales Value</b>
      <SearchByListValues
        placeholder="john@gmail.com, doe@gmail.com e.t.c ..."
        label="Emails"
        name="emails"
        validationSchema={EMAILS_LIST_VALIDATION_SCHEMA}
        isLoading={loading}
        fetchData={fetchData}
        initialValues={{ emails: "" }}
      />

      <div className={classes.tableContainer}>
        <ContainerWithLoader isLoading={loading} report={data}>
          <div className={generalClasses.tableHorizontalScroll}>
            <table
              className={cx(
                generalClasses.table,
                generalClasses.smTable,
                generalClasses.tableWithoutHover,
                generalClasses.noTextWrap
              )}
            >
              <TableHead />
              <TableBody />
            </table>
          </div>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default BasicAccountDetailsAndSalesValue;

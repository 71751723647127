import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { MainInfoValue } from "../../../../components/ui";
import { APIBilling } from "../../../../api";
import { getAvailableFreeResets } from "./utils";

const StatsResets = (): React.ReactElement => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [freeResetsCount, setFreeResetsCount] = useState(0);

  const { data, loading } = useSelector((state) => state.customer.plans);

  useEffect(() => {
    (async () => {
      try {
        const response = await APIBilling.getFreeResetsCountRequest(id);
        setFreeResetsCount(response.data.freeResetsCount);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  const countAvailableFreeResets = getAvailableFreeResets(data.plans);

  return (
    <div>
      {!isLoading && (
        <MainInfoValue title="Used free resets">
          <>{freeResetsCount}</>
        </MainInfoValue>
      )}
      {!loading && (
        <MainInfoValue title="Available free resets">
          <>{countAvailableFreeResets}</>
        </MainInfoValue>
      )}
    </div>
  );
};

export default StatsResets;

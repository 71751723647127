import React from "react";
import moment from "moment";
import cx from "classnames";
import { useSelector } from "react-redux";

import useStyles from "./useStyles";
import { IntlFormatUSD } from "../../../../../utils";
import { IAccountPlanData } from "../../../../../api/compliance/index.types";
import { CENTS_IN_DOLLAR, HOURS_12_FORMAT_WITH_DATE } from "../../../../../constants";
import { PLANS_COLUMNS } from "../TableHead";

interface IPlansSubTableprops {
  plans: IAccountPlanData[];
}
const PlansSubTable: React.FC<IPlansSubTableprops> = ({ plans }) => {
  const classes = useStyles();
  return (
    <>
      <tr className={cx(classes.planDataHeader)}>
        {PLANS_COLUMNS.map((c) => (
          <td key={c.title}>{c.title}</td>
        ))}
      </tr>

      {plans.map((plan) => {
        const isLastRow = plans[plans.length - 1].id === plan.id;
        return (
          <tr key={plan.id} className={cx({ [classes.borderBottom]: !!isLastRow })}>
            <td>{plan.plan.name}</td>
            {/*<td>{IntlFormatUSD(plan.price_usd / CENTS_IN_DOLLAR)}</td>*/}
            <td>{plan.expires}</td>
            <td>{plan.failures}</td>
            <td>{plan.status}</td>
            <td>{plan.passed && "passed"}</td>
            <td>{plan.start}</td>
            <td>{plan.canceled}</td>
            <td>{plan.campaign_property_id}</td>
            <td>{plan.upgrade}</td>
            <td>{plan.discount_code_id}</td>
            <td>{plan.free_resets}</td>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
        );
      })}
    </>
  );
};

const TableBody = (): React.ReactElement => {
  const classes = useStyles();
  const { accounts, plans } = useSelector((state) => state.complianceSearch.plans.data);

  return (
    <tbody>
      {accounts.map((account, index) => {
        const currentAccountPlans = plans.filter((plan) => plan.accounts_id === account.a_id);
        return (
          <React.Fragment key={index}>
            <tr className={cx({ [classes.borderBottom]: !currentAccountPlans.length })}>
              <td>{account.a_id}</td>
              <td>{account.name}</td>
              <td>{account.acc_email}</td>
              <td>{account.reg_ip}</td>
              <td>{account.phone}</td>
              <td>{account.country}</td>
              <td>{account.zip}</td>
              <td>{account.city}</td>
              <td>{account.address}</td>
              <td>{moment(account.joined).utc().format(HOURS_12_FORMAT_WITH_DATE)}</td>
              <td>{IntlFormatUSD(account.total_spent / CENTS_IN_DOLLAR)}</td>
              <td>{account.total_orders}</td>
              <td>{account.total_new_purchases}</td>
              <td>{account.total_paid_resets}</td>
              <td>{account.total_free_resets}</td>
              <td>{account.total_rebills}</td>
            </tr>

            {currentAccountPlans.length ? <PlansSubTable plans={currentAccountPlans} /> : null}
          </React.Fragment>
        );
      })}
    </tbody>
  );
};

export default TableBody;

import React from "react";
import { useSelector } from "react-redux";
import { IntlFormatUSD } from "../../../../../../utils";
import { CENTS_IN_DOLLAR } from "../../../../../../constants";

const TableBody = (): React.ReactElement => {
  const { data } = useSelector((state) => state.complianceSearch.plansStatus);
  return (
    <tbody>
      {data.map((r) => (
        <tr key={r.id}>
          <td>{r.accounts_id}</td>
          <td>{r.account.email}</td>
          <td>{r.account.name}</td>
          <td>{r.id}</td>
          <td>{r.plans_id}</td>
          <td>{IntlFormatUSD(r.price_usd / CENTS_IN_DOLLAR)}</td>
          <td>{r.expires}</td>
          <td>{r.failures}</td>
          <td>{r.status}</td>
          <td>{r.passed && "passed"}</td>
          <td>{r.start}</td>
          <td>{r.canceled}</td>
          <td>{r.campaign_property_id}</td>
          <td>{r.discount_code_id}</td>
          <td>{r.upgrade ? "true" : "false"}</td>
          <td>{r.free_resets}</td>
          <td>{r.orig_discount_id}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";

import { IReport } from "../../../../api/billing/index.types";
import useGeneralStyles from "../../../../useGeneralStyles";
import useStyles from "./useStyles";

interface IProps {
  report: IReport[];
}
interface IPlansToShow {
  [key: string]: number;
}

const ListQuantityOfProducts = ({ report }: IProps): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const classes = useStyles();

  const [allPlans, setAllPlans] = useState<IPlansToShow>({});
  const { plans } = useSelector((state) => state.plans.data);

  const [total, setTotal] = useState({
    purchase: 0,
    rebil: 0,
    resets: 0,
    freeResets: 0,
  });

  useEffect(() => {
    const sumByColumn = {
      purchase: 0,
      rebil: 0,
      resets: 0,
      freeResets: 0,
    };
    const plansToShow: IPlansToShow = {};

    plans.forEach(({ name }) => {
      if (name.includes("Gauntlet") || name === "Bootcamp") {
        plansToShow[name] = 0;
      }
    });
    report.forEach(
      ({
        p_name,
        bh_count_purchase,
        bh_count_free_resets,
        bh_count_rebill,
        bh_count_purchase_reset,
      }) => {
        plansToShow[p_name] = +bh_count_purchase;
        sumByColumn.freeResets += +bh_count_free_resets;
        sumByColumn.purchase += +bh_count_purchase;
        sumByColumn.rebil += +bh_count_rebill;
        sumByColumn.resets += +bh_count_purchase_reset;
      }
    );

    setTotal(sumByColumn);
    setAllPlans(plansToShow);
  }, [plans, report]);

  return (
    <table className={cx(generalClasses.table, generalClasses.tableWithoutHover)}>
      <thead>
        <tr>
          <th>Plan</th>
          <th className={classes.th}>Purchase</th>
          <th className={classes.th}>Rebil</th>
          <th>Resets</th>
          <th className={classes.th}>Free resets</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(allPlans)
          .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" }))
          .map((name) => {
            const current_plan = report.find(({ p_name }) => p_name === name);
            if (current_plan) {
              return (
                <tr key={name}>
                  <td>{name}</td>
                  <td>{current_plan.bh_count_purchase}</td>
                  <td>{current_plan.bh_count_rebill}</td>
                  <td>{current_plan.bh_count_purchase_reset}</td>
                  <td>{current_plan.bh_count_free_resets}</td>
                </tr>
              );
            }
            return (
              <tr key={name}>
                <td>{name}</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
            );
          })}
        <tr>
          <td>
            <b>Total</b>
          </td>
          <td>
            <b>{total.purchase}</b>
          </td>
          <td>
            <b>{total.rebil}</b>
          </td>
          <td>
            <b>{total.resets}</b>
          </td>
          <td>
            <b>{total.freeResets}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ListQuantityOfProducts;

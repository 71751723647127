import {
  IGetAllEntriesResponse,
  IGetCustomersLoginsResponse,
  IGetPaymentCardsResponse,
  IUniqueReqIpResponse,
  IGetAccountPlansDataResponse,
  IGetAccountPlansStatusResponse,
  TGetAccountsPurchasesResponse,
  IGetAccountsResetsRebillsNewPurchasesResponse,
  IBasicAccountDetailsAndSalesValueResponse,
  IGetAllEntriesRequestByValuesResponse,
} from "../../api/compliance/index.types";
import { ComplianceSearchAccountsAction, ComplianceSearchAccountsActionType } from "./index.types";

export const ComplianceSearchActions = {
  //SearchAccounts
  fetchComplianceSearchRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsRequest,
  }),
  fetchComplianceSearchSuccess: (
    payload: IGetAllEntriesResponse["data"]["accounts_table"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsSuccess,
    payload,
  }),
  fetchComplianceSearchFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsFailed,
  }),
  //Search logins
  fetchComplianceSearchLoginsRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsRequest,
  }),
  fetchComplianceSearchLoginsSuccess: (
    payload: IGetCustomersLoginsResponse["data"]["logins"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsSuccess,
    payload,
  }),
  fetchComplianceSearchLoginsFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsFailed,
  }),
  // Search unique req ip
  fetchComplianceSearchUniqueReqIpRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpRequest,
  }),
  fetchComplianceSearchUniqueReqIpSuccess: (
    payload: IUniqueReqIpResponse["data"]["usageData"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpSuccess,
    payload,
  }),
  fetchComplianceSearchUniqueReqIpFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpFailed,
  }),
  // SearchPaymentCards
  fetchComplianceSearchPaymentCardsRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsRequest,
  }),
  fetchComplianceSearchPaymentCardsSuccess: (
    payload: IGetPaymentCardsResponse["data"]["paymentCards"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsSuccess,
    payload,
  }),
  fetchComplianceSearchPaymentCardsFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsFailed,
  }),
  // SearchPlans
  fetchComplianceSearchPlansRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansRequest,
  }),
  fetchComplianceSearchPlansSuccess: (
    payload: IGetAccountPlansDataResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansSuccess,
    payload,
  }),
  fetchComplianceSearchPlansFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansFailed,
  }),
  // SearchPlansStatus
  fetchComplianceSearchPlansStatusRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusRequest,
  }),
  fetchComplianceSearchPlansStatusSuccess: (
    payload: IGetAccountPlansStatusResponse["data"]["accounts"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusSuccess,
    payload,
  }),
  fetchComplianceSearchPlansStatusFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusFailed,
  }),
  // SearchPurchases
  fetchComplianceSearchPurchasesRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesRequest,
  }),
  fetchComplianceSearchPurchasesSuccess: (
    payload: TGetAccountsPurchasesResponse["data"]["purchasesHistory"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesSuccess,
    payload,
  }),
  fetchComplianceSearchPurchasesFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesFailed,
  }),
  // SearchResetsRebillsNewPurchases
  fetchComplianceSearchResetsRebillsNewPurchasesRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesRequest,
  }),
  fetchComplianceSearchResetsRebillsNewPurchasesSuccess: (
    payload: IGetAccountsResetsRebillsNewPurchasesResponse["data"]["accounts"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesSuccess,
    payload,
  }),
  fetchComplianceSearchResetsRebillsNewPurchasesFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesFailed,
  }),
  // BasicAccountDetailsAndSalesValue
  fetchComplianceSearchBasicAccountDetailsAndSalesValueRequest:
    (): ComplianceSearchAccountsAction => ({
      type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueRequest,
    }),
  fetchComplianceSearchBasicAccountDetailsAndSalesValueSuccess: (
    payload: IBasicAccountDetailsAndSalesValueResponse["data"]["accounts"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueSuccess,
    payload,
  }),
  fetchComplianceSearchBasicAccountDetailsAndSalesValueFailed:
    (): ComplianceSearchAccountsAction => ({
      type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueFailed,
    }),
  // AccountSearchOnNameVariations
  fetchComplianceSearchByNameRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsRequest,
  }),
  fetchComplianceSearchByNameSuccess: (
    payload: IGetAllEntriesRequestByValuesResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsSuccess,
    payload,
  }),
  fetchComplianceSearchByNameFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsFailed,
  }),
  // AccountSearchByPhone
  fetchComplianceSearchByPhoneRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneRequest,
  }),
  fetchComplianceSearchByPhoneSuccess: (
    payload: IGetAllEntriesRequestByValuesResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneSuccess,
    payload,
  }),
  fetchComplianceSearchByPhoneFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneFailed,
  }),
};

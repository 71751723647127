import React from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "../../../useStyles";
import useGeneralStyles from "../../../../../useGeneralStyles";
import { ComplianceAPI } from "../../../../../api/compliance";
import { getQueryStringByStringValues } from "../../../utils";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import SearchByListValues from "../../SearchByListValues";
import { ContainerWithLoader } from "../../../../../components/ui";
import { ComplianceSearchActions } from "../../../../../reducers/ComplianceSearchReducer/actions";
import { EMAILS_LIST_VALIDATION_SCHEMA } from "../../../../../utils/validation";

const AllPurchases = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const dispatch = useDispatch();

  const { loading, data } = useSelector((state) => state.complianceSearch.purchasesHistory);
  const fetchData = async (values: { emails: string }) => {
    try {
      dispatch(ComplianceSearchActions.fetchComplianceSearchPurchasesRequest());
      const response = await ComplianceAPI.getAccountsPurchases(
        getQueryStringByStringValues("emails", values.emails)
      );
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchPurchasesSuccess(
          response.data.purchasesHistory
        )
      );
    } catch (error) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchPurchasesFailed());
      console.log("error fetching AllPurchases data", error);
    }
  };

  return (
    <div className={classes.container}>
      <b className={classes.title}>Search all purchases</b>
      <SearchByListValues
        validationSchema={EMAILS_LIST_VALIDATION_SCHEMA}
        isLoading={loading}
        fetchData={fetchData}
        placeholder="john@gmail.com, doe@gmail.com e.t.c ..."
        label="Emails"
        name="emails"
        initialValues={{ emails: "" }}
      />

      <div className={classes.tableContainer}>
        <ContainerWithLoader isLoading={loading} report={data}>
          <div className={generalClasses.tableHorizontalScroll}>
            <table
              className={cx(
                generalClasses.table,
                generalClasses.smTable,
                generalClasses.tableWithoutHover,
                generalClasses.noTextWrap
              )}
            >
              <TableHead />
              <TableBody />
            </table>
          </div>
        </ContainerWithLoader>
      </div>
    </div>
  );
};

export default AllPurchases;

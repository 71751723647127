import React from "react";
import { useSelector } from "react-redux";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cx from "classnames";

import useStyles from "./useStyles";
import { DATE_FORMAT } from "../../../../../../constants";

const TableBody = (): React.ReactElement => {
  const history = useHistory();
  const classes = useStyles();
  const { data } = useSelector((state) => state.suspiciousCustomers);
  const handleRowClick = (id: number) => history.push(`customer/${id}`);
  return (
    <tbody>
      {data.map(({ id, name, email, suspicious_accounts_notes, banned_account }) => {
        return (
          <tr
            key={id}
            onClick={() => handleRowClick(id)}
            className={cx({ [classes.banned]: banned_account })}
          >
            <td>{id}</td>
            <td>{name}</td>
            <td>{email}</td>
            <td>
              <List>
                {banned_account && (
                  <ListItemText>
                    Banned on: {moment(banned_account.timestamp).format(DATE_FORMAT)}
                  </ListItemText>
                )}
                {suspicious_accounts_notes.map(({ id, note }) => (
                  <ListItem className={classes.listItem} key={id}>
                    <ListItemText>- {note}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

import moment from "moment";
import momentTz from "moment-timezone";

import { DATE_FORMAT, HOURS_12_FORMAT } from "../constants";

const quarter = moment().quarter();
const getStartOfDate = (amount: number) =>
  moment().subtract(amount, "months").startOf("month").format(DATE_FORMAT);
const getEndOfDate = (amount: number) =>
  moment().subtract(amount, "months").endOf("month").format(DATE_FORMAT);

export const getTimeByAmericaChicagoTz = (date: number | string, isFullDate?: boolean): string =>
  momentTz(date)
    .tz("America/Chicago")
    .format(isFullDate ? `${DATE_FORMAT} (${HOURS_12_FORMAT})` : HOURS_12_FORMAT);
export const getTypeTime = (date: number | string): string =>
  momentTz(date).tz("America/Chicago").format("zz");
export const CURRENT_TIME_ZONE_ABBREVIATION = momentTz.tz(momentTz.tz.guess()).zoneAbbr();

export const MOMENT_DAYS = {
  today: moment().startOf("day").format(DATE_FORMAT).toString(),
  yesterday: moment().add(-1, "days").format(DATE_FORMAT).toString(),
  thisMonthBegin: moment().startOf("month").format(DATE_FORMAT),
  thisMonthEnd: moment().endOf("month").format(DATE_FORMAT),
  prevMonthBegin: moment().add(-1, "M").startOf("month").format(DATE_FORMAT),
  prevMonthEnd: moment().subtract(1, "months").endOf("month").format(DATE_FORMAT),

  oneMonthAgoBegin: getStartOfDate(1),
  oneMonthAgoEnd: getEndOfDate(1),
  towMonthAgoBegin: getStartOfDate(2),
  twoMonthAgoEnd: getEndOfDate(2),
  treMonthAgoBegin: getStartOfDate(3),
  treMonthAgoEnd: getEndOfDate(3),
  fourMonthAgoBegin: getStartOfDate(4),
  fourMonthAgoEnd: getEndOfDate(4),
  fiveMonthAgoBegin: getStartOfDate(5),
  fiveMonthAgoEnd: getEndOfDate(5),
  sixMonthAgoBegin: getStartOfDate(6),
  sixMonthAgoEnd: getEndOfDate(6),
  sevenMonthAgoBegin: getStartOfDate(7),
  sevenMonthAgoEnd: getEndOfDate(7),

  thisQuarterBegin: moment().quarter(quarter).startOf("quarter").format(DATE_FORMAT),
  nextQuarterBegin: moment().quarter(quarter).endOf("quarter").format(DATE_FORMAT),
  thisWeekBegin: moment().day(1).format(DATE_FORMAT),
  thisWeekEnd: moment().day(7).format(DATE_FORMAT),
  thisYearBegin: moment().startOf("year").format(DATE_FORMAT),
  thisYearEnd: moment().endOf("year").format(DATE_FORMAT),
  lastYearBegin: moment().startOf("year").subtract(1, "years").format(DATE_FORMAT).toString(),
  lastYearEnd: moment().endOf("year").subtract(1, "years").format(DATE_FORMAT).toString(),
};

export const MOMENT_PERIODS_BACK = {
  days30back: moment().startOf("day").subtract(30, "days").format(DATE_FORMAT).toString(),
  weeks25back: moment().startOf("day").subtract(25, "weeks").format(DATE_FORMAT).toString(),
  year3back: moment().startOf("day").subtract(3, "years").format(DATE_FORMAT).toString(),
};

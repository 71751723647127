import { combineReducers } from "redux";

import customers, { CustomersState } from "./customersReducer";
import discountCodes, { DiscountCodesState } from "./discountCodesReducer";
import discountCodesLogs, { IDiscountCodesLogsState } from "./discountCodesLogsReducer";
import plans, { PlansState } from "./plansReducer";
import feed, { FeedState } from "./feedReducer";
import userDiscountCodes, { IUserDiscountCodesState } from "./userDiscountReducer";
import agents, { IAgentsState } from "./agentsReducer";
import charges, { IDayChargesState } from "./chargesReducer";
import latestCharges from "./latestFailedChargesReducer";
import agentsLogsActivityReducer, { IAgentsActivityLogsState } from "./agentsLogsActivityReducer";
import fundedTraders, { IFundedTradersState } from "./fundedTradersReducer";
import fundedTrader from "./fundedTraderReducer";
import { IFundedTraderState } from "./fundedTraderReducer/index.types";
import evaluations, { IEvaluationsState } from "./evaluationsReducer";
import rithmicAccounts, { IRithmicAccountsState } from "./rithmicAccounts";
import cappedSteppedAccounts, { ICappedSteppedAccountsState } from "./cappedSteppedAccountsReducer";
import customer from "./customerReducer/";
import { ICustomerState } from "./customerReducer/index.types";
import suspiciousCustomers from "./SuspiciousCustomersReducer";
import { ISuspiciousCustomersState } from "./SuspiciousCustomersReducer/index.types";
import heliosAdmin from "./heliosAdminReducer";
import { IHeliosAdminState } from "./heliosAdminReducer/index.types";
import forcedToLiveAccounts from "./forcedToLiveAccountsReducer";
import { IForcedToLiveAccountsState } from "./forcedToLiveAccountsReducer/index.types";
import fundedTraderFiles from "./fundedTraderFilesReducer";
import { IFundedTraderFilesState } from "./fundedTraderFilesReducer/index.types";
import agent from "./agentReducer";
import { IAgentState } from "./agentReducer/index.types";
import polices from "./policesReducer";
import { IPolicesState } from "./policesReducer/index.types";
import fundedTradersStatusesList from "./fundedTradersStatusesList";
import { IFundedTradersStatusesListState } from "./fundedTradersStatusesList/index.types";
import affiliates from "./affiliates/actions";
import { IAffiliatesState } from "./affiliates/index.types";
import complianceWatchlist from "./compliance";
import { IComplianceWatchlistState } from "./compliance/index.types";
import complianceSearch from "./ComplianceSearchReducer";
import { IComplianceSearchAccountsState } from "./ComplianceSearchReducer/index.types";

export interface ReduxState {
  agent: IAgentState;
  agents: IAgentsState;
  agentsLogsActivityReducer: IAgentsActivityLogsState;
  affiliates: IAffiliatesState;
  cappedSteppedAccounts: ICappedSteppedAccountsState;
  charges: IDayChargesState;
  customer: ICustomerState;
  customers: CustomersState;
  discountCodes: DiscountCodesState;
  discountCodesLogs: IDiscountCodesLogsState;
  evaluations: IEvaluationsState;
  feed: FeedState;
  forcedToLiveAccounts: IForcedToLiveAccountsState;
  fundedTrader: IFundedTraderState;
  fundedTraders: IFundedTradersState;
  fundedTraderFiles: IFundedTraderFilesState;
  fundedTradersStatusesList: IFundedTradersStatusesListState;
  heliosAdmin: IHeliosAdminState;
  latestCharges: IDayChargesState;
  plans: PlansState;
  polices: IPolicesState;
  rithmicAccounts: IRithmicAccountsState;
  suspiciousCustomers: ISuspiciousCustomersState;
  userDiscountCodes: IUserDiscountCodesState;
  complianceWatchlist: IComplianceWatchlistState;
  complianceSearch: IComplianceSearchAccountsState;
}

export default combineReducers({
  agent,
  agents,
  agentsLogsActivityReducer,
  affiliates,
  cappedSteppedAccounts,
  charges,
  customer,
  customers,
  complianceWatchlist,
  complianceSearch,
  discountCodes,
  discountCodesLogs,
  evaluations,
  feed,
  forcedToLiveAccounts,
  fundedTrader,
  fundedTraders,
  fundedTraderFiles,
  fundedTradersStatusesList,
  heliosAdmin,
  latestCharges,
  plans,
  polices,
  rithmicAccounts,
  suspiciousCustomers,
  userDiscountCodes,
});

import React from "react";
import SearchAccountsByName from "./components/SearchAccountsByName";
import SearchAccountsByPhone from "./components/SearchAccountsByPhone";

const AllAccountSearch = (): React.ReactElement => {
  return (
    <div>
      <SearchAccountsByName />
      <SearchAccountsByPhone />
    </div>
  );
};

export default AllAccountSearch;

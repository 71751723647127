import * as Yup from "yup";
import m from "moment";

import { ICode, IGetNewPrice } from "./types";
import { RESET_FLAG_ID } from "./PlansOptions";
import { IPlan } from "../../../api/plans/index.types";

const MAX_CODE_LENGTH = 12;

export const randomSuffix = (): string =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substring(0, 5);

export const calculatePrice = (
  number: number,
  amount: string | number,
  isPercent: boolean
): number => {
  let sum = number * (1 - Number(amount) / 100);
  if (!isPercent) sum = Number(amount);
  return Number(sum.toFixed(2));
};

export const getPlanPrice = (plans: IPlan[], plan: number): number =>
  plans.filter((p) => p.id === plan)[0]?.price_usd;

export const getNewPrice = ({ plans, code }: IGetNewPrice): number => {
  const price = getPlanPrice(plans, code.plan);
  let number: number = price ? price / 100 : 0;
  if (code.plan === RESET_FLAG_ID) {
    number = 100;
  }
  return calculatePrice(number, code.amount, code.percent);
};

export const getSuffix = (times: string): string[] => {
  const newSuffix = [];
  for (let index = 0; index < parseInt(times, 10); index++) {
    newSuffix.push(randomSuffix());
  }
  return newSuffix;
};

export const getPlanId = (isReset: boolean, plan: { name: string; id: number }): number => {
  if (isReset) {
    return -2;
  }
  if (!plan.name) {
    return -1;
  }
  return plan.id;
};

export const INITIAL_CODE: ICode = {
  code: "",
  suffix: [],
  times: 0,
  amount: 0,
  descr: "",
  qty: 1,
  plan: 0,
  planPrice: 0,
  percent: true,
  one_time: false,
  active: true,
  expires: null,
  accounts_id: null,
  reset: null,
  autoExpires: false,
  bulkAssignEmails: null,
  isBulkValidEmails: true,
  bulkEmailsError: "",
  autoExpiration: false,
  expiration: null,
  isBatchCreate: false,
  isReset: false,
  use_expiration: false,
  use_failover_amt: false,
  failover_amt: null,
  use_failover_type: false,
  failover_type: null,
  use_expiration_days: false,
  expiration_days: 30,
};

export const FAILOVER_TYPE_OPTIONS = [
  {
    key: "percent",
    value: "percent",
    label: "Percent",
  },
  {
    key: "flat",
    value: "flat",
    label: "Flat",
  },
];

export const DISCOUNT_CODE_VALIDATION_SCHEMA = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .test(
      "is-not-empty-spaces",
      "Code can't contain whitespaces",
      (value = "") => !/\s/g.test(value)
    )
    .min(3, "Code should be at least 3 characters")
    .max(MAX_CODE_LENGTH, `Code should be at most ${MAX_CODE_LENGTH} characters`),
  plan: Yup.number()
    .required("Plan is required")
    .test("plan", "Plan is required", (number) => number !== 0),
  amount: Yup.number()
    .when(["percent"], {
      is: true,
      then: Yup.number()
        .required("Percent is required")
        .min(1, "Percent should be more than 0")
        .max(100, "Percent shouldn't be more than 100"),
    })
    .when(["percent"], {
      is: false,
      then: Yup.number()
        .required("Discount amount is required")
        .min(1, "Discount amount should be more than 0"),
    }),
  descr: Yup.string().max(100, "Description shouldn't be more than 100 characters"),
  expires: Yup.date()
    .typeError("Invalid date")
    .nullable()
    .when(["autoExpires"], {
      is: true,
      then: Yup.date()
        .required("Expiry Date is required")
        .typeError("Invalid date")
        .min(new Date(), "Date can't be in the past"),
    })
    .default(null),
  expiration: Yup.date()
    .typeError("Invalid date")
    .nullable()
    .when(["use_expiration_days", "use_expiration"], {
      is: (use_expiration_days: boolean, use_expiration: boolean) => {
        return !use_expiration_days && use_expiration;
      },
      then: Yup.date()
        .required("Expiration date is required")
        .typeError("Invalid date")
        .min(m().add(29, "days"), "Expiration date must be at least 30 days from today."),
    }),
  expiration_days: Yup.number()
    .nullable()
    .integer("Expiration days should be an integer")
    .when(["use_expiration_days"], {
      is: true,
      then: Yup.number()
        .required("Expiration days is required")
        .min(30, "Expiration days should be more than or equal 30"),
    }),
  failover_amt: Yup.number()
    .nullable()
    .typeError("Required")
    .integer("Failover amount should be an integer")
    .when(["use_failover_amt"], {
      is: true,
      then: Yup.number()
        .typeError("Required")
        .nullable()
        .required("Failover amount is required")
        .when("failover_type", {
          is: "percent",
          then: Yup.number()
            .typeError("Required")
            .nullable()
            .min(1, "Failover amount should be more then 0")
            .max(99, "Failover amount shouldn't be more than 99"),
        })
        .when("failover_type", {
          is: "flat",
          then: Yup.number()
            .typeError("Required")
            .nullable()
            .min(1, "Failover amount should be more then 0"),
        }),
    }),
  failover_type: Yup.string()
    .typeError("Required")
    .nullable()
    .when(["use_failover_type"], {
      is: true,
      then: Yup.string().nullable().required("Type is required"),
    }),
});

import { TChargeStatuses } from "../api/charges/index.types";

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export const setPage = (
  page: number,
  count: number,
  limit: number,
  cb: (a: number) => void
): void => {
  const max = Math.ceil(count / limit);
  let p = page;
  if (p >= max) p = max - 1;
  if (p <= 0) p = 0;
  cb(p);
};

export const IntlFormatUSD = (val: number, options?: Record<string, unknown>): string =>
  new Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
    ...options,
  }).format(val);

export const generateRandomString = (length = 7): string =>
  Math.random().toString(36).substring(2, length);

interface IGetSumAllCharges {
  status: TChargeStatuses;
  amount: number;
}
export const getSumAllCharges = <T extends IGetSumAllCharges>(report: T[]): number =>
  report.reduce((acc, currentValue) => {
    if (["succeeded", "S", "complete", "approved"].includes(currentValue.status)) {
      return currentValue.amount + acc;
    }
    return acc;
  }, 0);

import httpClient from "../apiClient";
import {
  TGetProductsStatisticsByDatesRequest,
  TGetReportRequest,
  TGetProductsStatisticsRequest,
  TGetRebillStatisticsRequest,
  TGetNetSalesValuesRequest,
  TGetBillingStatusesRequest,
  TGetThisDayChargesRequest,
  TGetFreeResetsCountRequest,
  TGetSalesBreakdownPerCountryRequest,
  TGetSalesByPeriodPerCountryRequest,
} from "./index.types";
export const BILLING_API_URLS = {
  productsStatisticsByDates: "billing/productsStatisticsByDates",
  getReport: "billing/getReport",
  getNetSalesValuesReport: "/billing/getNetSalesValuesReport",
  productsStatistics: "billing/productsStatistics",
  getRebillStatistics: "billing/getRebillStatistics",
  statuses: "billing/statuses",
  billing: "billing",
  getFreeResetsCount: "/billing/getFreeResetsCount",
  salesBreakdownPerCountry: "/billing/sales_breakdown_per_country",
  salesByPeriodPerCountry: "/billing/sales-by-period-per-country",
} as const;

export class BillingAPI {
  static getProductsStatisticsByDatesRequest: TGetProductsStatisticsByDatesRequest = (data) =>
    httpClient.post(BILLING_API_URLS.productsStatisticsByDates, data);
  static getReportRequest: TGetReportRequest = (data) =>
    httpClient.post(BILLING_API_URLS.getReport, data);
  static getProductsStatisticsRequest: TGetProductsStatisticsRequest = (
    beginPeriod,
    endPeriod,
    period
  ) => httpClient.post(BILLING_API_URLS.productsStatistics, { beginPeriod, endPeriod, period });
  static getRebillStatisticsRequest: TGetRebillStatisticsRequest = (beginPeriod, endPeriod) =>
    httpClient.post(BILLING_API_URLS.getRebillStatistics, { beginPeriod, endPeriod });
  static getNetSalesValuesRequest: TGetNetSalesValuesRequest = (startDate, endDate, reportType) =>
    httpClient.post(BILLING_API_URLS.getNetSalesValuesReport, { startDate, endDate, reportType });
  static getBillingStatusesRequest: TGetBillingStatusesRequest = (data) =>
    httpClient.post(BILLING_API_URLS.statuses, data);
  static getThisDayChargesRequest: TGetThisDayChargesRequest = (data) =>
    httpClient.post(BILLING_API_URLS.billing, data);
  static getFreeResetsCountRequest: TGetFreeResetsCountRequest = (id) =>
    httpClient.post(`${BILLING_API_URLS.getFreeResetsCount}/${id}`);
  static getSalesBreakdownPerCountryRequest: TGetSalesBreakdownPerCountryRequest = (query) =>
    httpClient.get(`${BILLING_API_URLS.salesBreakdownPerCountry}?${query}`);
  static getSalesByPeriodPerCountryRequest: TGetSalesByPeriodPerCountryRequest = (query, signal) =>
    httpClient.get(`${BILLING_API_URLS.salesByPeriodPerCountry}?${query}`, { signal });
}

import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";

import {
  Evaluations,
  IntercomData,
  FreeResets,
  CustomerNotes,
  ComplianceNotes,
} from "../../../pages/Customer/components/";
import { BanAccount, BaseCustomerInfo } from "../index";

import { useAdminProviderDataContext } from "../../../context";
import { FundedProfileNotesTable } from "../../../pages/FundedTrader/components";

import {
  PEPSelect,
  E2TAffiliateValue,
  E2TInterview,
  OnVocationUntilDateSelect,
  PriorityStatusControls,
  ProfessionalLiveSimData,
  ToggleStartNextMonth,
  Over8KWithdrawals,
  ForcedToLive,
} from "../../../pages/FundedTrader/components";

const useStyles = makeStyles({
  container: {
    minHeight: 350,
  },
  notesContainer: {
    marginTop: 5,
  },
  fundedProfileDataContainer: {
    display: "grid",
    rowGap: 8,
  },
});
const MainCustomerInfo: React.FC = () => {
  const classes = useStyles();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const matchCustomerPage = useRouteMatch("/customer/:id");
  const matchFundedTraderPage = useRouteMatch("/fundedTraders/:id");

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={4}>
        <BaseCustomerInfo />
      </Grid>
      <Grid item xs={3}>
        {matchFundedTraderPage && (
          <div className={classes.fundedProfileDataContainer}>
            <PEPSelect />
            <E2TAffiliateValue />
            <E2TInterview />
            <OnVocationUntilDateSelect />
            <PriorityStatusControls />
            <ProfessionalLiveSimData />
            <Over8KWithdrawals />
            <ForcedToLive />
            <ToggleStartNextMonth />
          </div>
        )}
        {matchCustomerPage && (
          <>
            {allowed_features.read_customer_intercom_data && <IntercomData />}
            <Evaluations />
            <FreeResets />
          </>
        )}
      </Grid>
      <Grid item xs={5}>
        {allowed_features.ban_account && <BanAccount />}
        <CustomerNotes isShowSuspiciousNotes />
        {matchFundedTraderPage && <FundedProfileNotesTable />}
        {matchCustomerPage && (
          <div className={classes.notesContainer}>
            <CustomerNotes />
            <ComplianceNotes />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default MainCustomerInfo;

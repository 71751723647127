import React from "react";
import { useSelector } from "react-redux";

import { IntlFormatUSD } from "../../../../../../utils";
import { CENTS_IN_DOLLAR } from "../../../../../../constants";

const TableBody = (): React.ReactElement => {
  const { data } = useSelector((state) => state.complianceSearch.purchasesHistory);
  return (
    <tbody>
      {data.map((r) => (
        <tr key={r.id}>
          <td>{r.account.id}</td>
          <td>{r.account.email}</td>
          <td>{r.account.name}</td>
          <td>{r.id}</td>
          <td>{r.plan.name}</td>
          <td>{r.plan.id}</td>
          <td>{IntlFormatUSD(r.price_usd / CENTS_IN_DOLLAR)}</td>
          <td>{r.status}</td>
          <td>{r.stripe_ch}</td>
          <td>{r.purchase_date}</td>
          <td>{r.rebill ? "true" : "false"}</td>
          <td>{r.reset ? "true" : "false"}</td>
          <td>{r.free_reset ? "true" : "false"}</td>
          <td>{r.discount_code?.code}</td>
          <td>{r.discount_code?.desc}</td>
          <td>{r.discount_code?.type}</td>
          <td>{r.discount_code?.amt}</td>
          <td>{r.discount_code?.one_time ? "true" : "false"}</td>
          <td>{r.discount_code ? "false" : "true"}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;

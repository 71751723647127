import React from "react";
import { makeStyles } from "@material-ui/core/";
import { useSelector } from "react-redux";

import { APIAgentActivityLog, APIIntercom } from "../../../../api";

import DialogConfirm from "../DialogConfirm/DialogConfirm";
import CreateDiscount from "../CreateDiscount";
import DeleteCustomerAccount from "../DeleteCustomerAccount/DeleteCustomerAccount";

import { useCustomerProvider, useAdminProviderDataContext } from "../../../../context";
import { CompleteEvaluation } from "../index";

const useStyles = makeStyles({
  controlsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px 0",
  },
});

interface IProps {
  setPopup: (popup: string) => void;
}

const ManagementConfirmWindows: React.FC<IProps> = ({ setPopup }) => {
  const classes = useStyles();
  const { customer } = useCustomerProvider();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const { passedPlan } = useSelector((state) => state.customer.plans.data);

  return (
    <div>
      <b>Management:</b>
      <div className={classes.controlsContainer}>
        <DialogConfirm
          mainButtonProps={{
            disabled: !!passedPlan,
          }}
          isShowMainButton
          dialogTitle="Reset User"
          contentText="Are you sure you want to reset user?"
          buttonText="Reset User"
          onClickConfirm={() => {
            APIAgentActivityLog.resetAccountLogRequest({ accounts_id: customer.id });
            window.open(`https://api.earn2trade.com/mgmt/clear_reset/${customer.xid}`);
          }}
        />
        <DialogConfirm
          mainButtonProps={{
            disabled: !!passedPlan,
          }}
          isShowMainButton
          dialogTitle="Clear Failure"
          contentText="Are you sure you want to clear failure?"
          buttonText="Clear Failure"
          onClickConfirm={() => {
            APIIntercom.clearFailIntercomContactLogRequest({
              id: customer.xid,
              accounts_id: customer.id,
            });
            window.open(`https://api.earn2trade.com/mgmt/clear_fail/${customer.xid}`);
          }}
        />

        <DialogConfirm
          isShowMainButton
          dialogTitle="Create Finamark"
          contentText="Are you sure you want to create finamark ?"
          buttonText="Create Finamark"
          onClickConfirm={() => setPopup("fina-create")}
        />

        <DialogConfirm
          isShowMainButton
          dialogTitle="Extend Finamark"
          contentText="Are you sure you want to extend finamark ?"
          buttonText="Extend Finamark"
          onClickConfirm={() => setPopup("fina-extend")}
        />
        {allowed_features.create_discount_code_for_customer && <CreateDiscount />}

        {allowed_features.delete_customer_account && !!customer.status && (
          <DeleteCustomerAccount cus_id={customer.stripe_customer} />
        )}
      </div>

      <div className={classes.controlsContainer}>
        <CompleteEvaluation />
      </div>
    </div>
  );
};

export default ManagementConfirmWindows;

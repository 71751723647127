import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "../../context";
import { APIAgents } from "../../api";
import { SVGArrow } from "../../components/svgs";
import AgentLogs from "./components/AgentLogs";
import AgentForm from "./components/AgentForm";
import { AgentActions } from "../../reducers/agentReducer/actions";
import { AGENTS_API_URLS } from "../../api/agents";

const Agent = (): React.ReactElement => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    // loginAndQuery,
    hasExpired,
  } = useAuthContext();
  const { data } = useSelector((state) => state.agent);

  const handleOnClick = useCallback(() => history.push("/agents"), [history]);

  useQuery({
    queryKey: [`get/${AGENTS_API_URLS.agents}`, +id],
    queryFn: async () => {
      dispatch(AgentActions.fetchAgentRequest());
      const { data } = await APIAgents.getAgentRequest(id);
      dispatch(AgentActions.fetchAgentSuccess(data));
      return data;
    },
    onError: (error) => {
      console.error("error fetching agent data", error);
      hasExpired();
      dispatch(AgentActions.fetchAgentFailed());
    },
  });

  // useEffect(() => {
  // TODO move out
  // loginAndQuery(getAgent);
  // }, []);

  return (
    <div className="customer agent">
      <div className="title">
        <SVGArrow onClick={handleOnClick} />
        {data.name} <div style={{ float: "right" }}>ID: {data.id}</div>
      </div>

      <AgentForm />
      <AgentLogs />
    </div>
  );
};

export default Agent;

import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  listItem: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  banned: {
    backgroundColor: "#F88F703b",
  },
});

export default useStyles;

import httpClient from "../apiClient";

import * as Type from "./index.types";
export const EVALUATIONS_API_URLS = {
  getAllEvaluations: "/evaluations",
  getEvaluations: (e2tId: string) => `/evaluations/${e2tId}`,
  setEvaluationStep: "/evaluations/step",
  evaluationComplete: "/evaluations/complete",
  customerEvaluationCompleteHistory: "/evaluations/history",
} as const;

export class EvaluationsAPI {
  static getAllEvaluationsRequest: Type.TGetAllEvaluationsRequest = (body, signal) =>
    httpClient.post(EVALUATIONS_API_URLS.getAllEvaluations, body, { signal });
  static getCustomerEvaluationsRequest: Type.TGetCustomerEvaluationsRequest = (e2tId, email) =>
    httpClient.post(EVALUATIONS_API_URLS.getEvaluations(e2tId), { email });
  static setCustomerEvaluationsSteps: Type.TSetCustomerEvaluationsSteps = (e2tId, body) =>
    httpClient.post(`${EVALUATIONS_API_URLS.setEvaluationStep}/${e2tId}`, body);
  static evaluationCompleteRequest: Type.TCompeliteEvaluation = (e2tId: string, body) =>
    httpClient.post(`${EVALUATIONS_API_URLS.evaluationComplete}/${e2tId}`, body);
  static getCustomerEvaluationCompleteHistoryRequest: Type.TGetCustomerEvaluationCompleteHistory = (
    query
  ) => httpClient.get(`${EVALUATIONS_API_URLS.customerEvaluationCompleteHistory}?${query}`);
  static updateCustomerEvaluationCompleteHistoryRequest: Type.TUpdateCustomerEvaluationCompleteHistory =
    (body) => httpClient.patch(`${EVALUATIONS_API_URLS.customerEvaluationCompleteHistory}`, body);
}

import React, { useState } from "react";

import CreateAgent from "./components/CreateAgent";
import AgentsList from "./components/AgentsList";
import { useAdminProviderDataContext } from "../../context/AdminProviderData";

const Agents = (): React.ReactElement => {
  const [menu, setMenu] = useState<number>(0);
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  return (
    <>
      <div className="top-nav">
        <div
          className={menu === 0 ? "active" : ""}
          onClick={() => setMenu(0)}
          role="button"
          tabIndex={-1}
        >
          All
        </div>
        {allowed_features.create_new_agent && (
          <div
            className={menu === 1 ? "active" : ""}
            onClick={() => setMenu(1)}
            role="button"
            tabIndex={-1}
            data-test="button-add"
          >
            Add
          </div>
        )}
      </div>

      {menu === 0 ? <AgentsList /> : <CreateAgent />}
    </>
  );
};

export default Agents;

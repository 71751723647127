import httpClient from "../apiClient";
import * as Type from "./index.types";

export const COMPLIANCE_API_URLS = {
  getNotes: "compliance/watchlist/notes",
  getAllNotes: "compliance/watchlist/notes/all",
  getEntries: "compliance/search-entities",
  getCustomersLogins: "compliance/search/logins",
  getUniqueReqIp: "compliance/search/unique-req-ip",
  getPaymentCards: "compliance/search/payment-cards",
  getAccountPlans: "compliance/search/account-plans",
  getAccountPlansStatus: "compliance/search/account-plans-status",
  getAccountsPurchases: "compliance/search/account-purchases",
  getAccountsResetsRebillsNewPurchases: "compliance/search/account-resets-rebills-new-purchases",
  getBasicAccountDetailsAndSalesValue: "compliance/search/account-basic-details-and-sales-value",
  getEntriesByName: "compliance/search/search-entities-by-values",
} as const;

export class ComplianceAPI {
  static getCustomerComplianceNotesRequest: Type.TGetCustomerComplianceNotes = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getNotes}?${query}`, { signal });
  static getAllComplianceNotesRequest: Type.TGetAllComplianceNotesRequest = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getAllNotes}?${query}`, { signal });
  static getAllEntriesRequest: Type.TGetAllEntriesRequest = (body, signal) =>
    httpClient.post(COMPLIANCE_API_URLS.getEntries, body, { signal });
  static getCustomersLoginsRequest: Type.TGetCustomersLoginsRequest = (query: string, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getCustomersLogins}?${query}`, { signal });
  static getUniqueReqIpRequest: Type.TGetUniqueReqIpRequest = (query: string, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getUniqueReqIp}?${query}`, { signal });
  static getPaymentCardsRequest: Type.TGetPaymentCardsRequest = (query: string, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getPaymentCards}?${query}`, { signal });
  static getAccountPlans: Type.TGetAccountPlans = (query: string) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getAccountPlans}?${query}`);
  static getAccountPlansStatus: Type.TGetAccountPlansStatus = (query: string) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getAccountPlansStatus}?${query}`);
  static getAccountsPurchases: Type.TGetAccountsPurchases = (query: string) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getAccountsPurchases}?${query}`);
  static getAccountsResetsRebillsNewPurchases: Type.TGetAccountsResetsRebillsNewPurchases = (
    query: string
  ) => httpClient.get(`${COMPLIANCE_API_URLS.getAccountsResetsRebillsNewPurchases}?${query}`);
  static getBasicAccountDetailsAndSalesValue: Type.TGetBasicAccountDetailsAndSalesValue = (query) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getBasicAccountDetailsAndSalesValue}?${query}`);
  static getAllEntriesRequestByValues: Type.TGetAllEntriesRequestByValuesRequest = (query) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getEntriesByName}?${query}`);
}

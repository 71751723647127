import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    fontSize: "22px",
    fontWeight: 800,
    background: "#1886cf",
    color: "white",
    padding: "20px 30px",
    margin: "-20px -30px 15px",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  customerId: {
    color: "#ffffff",
    fontSize: "12px",
    fontWeight: 500,
    marginTop: "4px",
    "&:after": {
      content: "'#'",
      position: "absolute",
      fontSize: "166px",
      fontWeight: 900,
      opacity: 0.3,
      top: "-50px",
      right: "-20px",
    },
  },
  title: {
    fontSize: "22px",
  },
  arrowContainer: {
    display: "flex",
    alignItems: "center",
  },
  customerNameContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  banDataContainer: {
    display: "flex",
  },
  banInfoContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
  },
});

export default useStyles;

import { ReduxAction } from "../../static-types";
import { IActivityLogsData } from "../../api/agentActivityLog/index.types";
import {
  IAccountNote,
  IGetAccountManagementDataResponse,
  IGetCustomerPlansResponse,
  IGetCustomerResponse,
} from "../../api/accounts/index.types";
import { IGetCustomerPaymentCardsResponse, IPaymentCard } from "../../api/paymentCards/index.types";
import { IGetSharedRegIpsResponse, TGetSharedIpsResponse } from "../../api/logins/index.types";
import { IGetCustomerComplianceNotesResponse } from "../../api/compliance/index.types";

export type CustomerAction =
  | CustomerAgentLogsFetchRequest
  | CustomerAgentLogsFetchSuccess
  | CustomerAgentLogsFetchFailed
  // Plans
  | CustomerPlansFetchRequest
  | CustomerPlansFetchSuccess
  | CustomerPlansFetchFailed
  // complianceNotes
  | CustomerComplianceNotesFetchRequest
  | CustomerComplianceNotesFetchSuccess
  | CustomerComplianceNotesFetchFailed
  // notes
  | CustomerNotesFetchRequest
  | CustomerNotesFetchSuccess
  | CustomerNotesFetchFailed
  // Suspicious notes
  | CustomerSuspiciousNotesFetchRequest
  | CustomerSuspiciousNotesFetchSuccess
  | CustomerSuspiciousNotesFetchFailed
  // Management data
  | CustomerManagementDataFetchRequest
  | CustomerManagementDataFetchSuccess
  | CustomerManagementDataFetchFailed
  // Payment cards
  | CustomerPaymentCardsFetchRequest
  | CustomerPaymentCardsFetchSuccess
  | CustomerPaymentCardsFetchFailed
  // Main data
  | CustomerMainDataFetchRequest
  | CustomerMainDataFetchSuccess
  | CustomerMainDataFetchFailed
  // SharedIps
  | CustomerSharedIpsDataFetchRequest
  | CustomerSharedIpsDataFetchSuccess
  | CustomerSharedIpsDataFetchFailed
  // Shared Reg Ips
  | CustomerSharedRegIpsFetchRequest
  | CustomerSharedRegIpsFetchSuccess
  | CustomerSharedRegIpsFetchFailed;

export interface CustomerAgentLogsFetchRequest extends ReduxAction {
  type: CustomerActionType.fetchAgentLogsRequest;
}
export interface CustomerAgentLogsFetchSuccess extends ReduxAction {
  type: CustomerActionType.fetchAgentLogsSuccess;
  payload: {
    logs: IActivityLogsData[];
    count: number;
  };
}
export interface CustomerAgentLogsFetchFailed extends ReduxAction {
  type: CustomerActionType.fetchAgentLogsFailed;
}
// Plans
export interface CustomerPlansFetchRequest extends ReduxAction {
  type: CustomerActionType.fetchCustomerPlansRequest;
}
export interface CustomerPlansFetchSuccess extends ReduxAction {
  type: CustomerActionType.fetchCustomerPlansSuccess;
  payload: IGetCustomerPlansResponse["data"];
}
export interface CustomerPlansFetchFailed extends ReduxAction {
  type: CustomerActionType.fetchCustomerPlansFailed;
}
// complianceNotes
export interface CustomerComplianceNotesFetchRequest extends ReduxAction {
  type: CustomerActionType.fetchCustomerComplianceNotesRequest;
}
export interface CustomerComplianceNotesFetchSuccess extends ReduxAction {
  type: CustomerActionType.fetchCustomerComplianceNotesSuccess;
  payload: IGetCustomerComplianceNotesResponse["data"];
}
export interface CustomerComplianceNotesFetchFailed extends ReduxAction {
  type: CustomerActionType.fetchCustomerComplianceNotesFailed;
}
// notes
export interface CustomerNotesFetchRequest extends ReduxAction {
  type: CustomerActionType.fetchCustomerNotesRequest;
}
export interface CustomerNotesFetchSuccess extends ReduxAction {
  type: CustomerActionType.fetchCustomerNotesSuccess;
  payload: {
    notes: IAccountNote[];
    count: number;
    countActive: number;
  };
}
export interface CustomerNotesFetchFailed extends ReduxAction {
  type: CustomerActionType.fetchCustomerNotesFailed;
}
// suspicious notes
export interface CustomerSuspiciousNotesFetchRequest extends ReduxAction {
  type: CustomerActionType.fetchCustomerSuspiciousNotesRequest;
}
export interface CustomerSuspiciousNotesFetchSuccess extends ReduxAction {
  type: CustomerActionType.fetchCustomerSuspiciousNotesSuccess;
  payload: {
    notes: IAccountNote[];
    count: number;
    countActive: number;
  };
}
export interface CustomerSuspiciousNotesFetchFailed extends ReduxAction {
  type: CustomerActionType.fetchCustomerSuspiciousNotesFailed;
}
// Management data
export interface CustomerManagementDataFetchRequest extends ReduxAction {
  type: CustomerActionType.fetchCustomerManagementDataRequest;
}
export interface CustomerManagementDataFetchSuccess extends ReduxAction {
  type: CustomerActionType.fetchCustomerManagementDataSuccess;
  payload: IGetAccountManagementDataResponse["data"];
}
export interface CustomerManagementDataFetchFailed extends ReduxAction {
  type: CustomerActionType.fetchCustomerManagementDataFailed;
}
// Payment cards
export interface CustomerPaymentCardsFetchRequest extends ReduxAction {
  type: CustomerActionType.fetchCustomerPaymentCardsRequest;
}
export interface CustomerPaymentCardsFetchSuccess extends ReduxAction {
  type: CustomerActionType.fetchCustomerPaymentCardsSuccess;
  payload: IGetCustomerPaymentCardsResponse["data"];
}
export interface CustomerPaymentCardsFetchFailed extends ReduxAction {
  type: CustomerActionType.fetchCustomerPaymentCardsFailed;
}
// Main data
export interface CustomerMainDataFetchRequest extends ReduxAction {
  type: CustomerActionType.fetchCustomerMainDataRequest;
}
export interface CustomerMainDataFetchSuccess extends ReduxAction {
  type: CustomerActionType.fetchCustomerMainDataSuccess;
  payload: Partial<IGetCustomerResponse["data"]>;
}
export interface CustomerMainDataFetchFailed extends ReduxAction {
  type: CustomerActionType.fetchCustomerMainDataFailed;
}
// SharedIps
export interface CustomerSharedIpsDataFetchRequest extends ReduxAction {
  type: CustomerActionType.fetchCustomerSharedIpsDataRequest;
}
export interface CustomerSharedIpsDataFetchSuccess extends ReduxAction {
  type: CustomerActionType.fetchCustomerSharedIpsDataSuccess;
  payload: TGetSharedIpsResponse["data"]["sharedIps"];
}
export interface CustomerSharedIpsDataFetchFailed extends ReduxAction {
  type: CustomerActionType.fetchCustomerSharedIpsDataFailed;
}
// Shared Reg Ips
export interface CustomerSharedRegIpsFetchRequest extends ReduxAction {
  type: CustomerActionType.fetchCustomerSharedRegIpsRequest;
}
export interface CustomerSharedRegIpsFetchSuccess extends ReduxAction {
  type: CustomerActionType.fetchCustomerSharedRegIpsSuccess;
  payload: IGetSharedRegIpsResponse["data"]["sharedRegIp"];
}
export interface CustomerSharedRegIpsFetchFailed extends ReduxAction {
  type: CustomerActionType.fetchCustomerSharedRegIpsFailed;
}

export enum CustomerActionType {
  fetchAgentLogsRequest = "customer/FetchAgentLogsRequest",
  fetchAgentLogsSuccess = "customer/FetchAgentLogsSuccess",
  fetchAgentLogsFailed = "customer/FetchAgentLogsFailed",
  // Plans
  fetchCustomerPlansRequest = "customer/FetchCustomerPlansRequest",
  fetchCustomerPlansSuccess = "customer/FetchCustomerPlansSuccess",
  fetchCustomerPlansFailed = "customer/FetchCustomerPlansFailed",
  // complianceNotes
  fetchCustomerComplianceNotesRequest = "customer/FetchCustomerComplianceNotesRequest",
  fetchCustomerComplianceNotesSuccess = "customer/FetchCustomerComplianceNotesSuccess",
  fetchCustomerComplianceNotesFailed = "customer/FetchCustomerComplianceNotesFailed",
  // notes
  fetchCustomerNotesRequest = "customer/FetchCustomerNotesRequest",
  fetchCustomerNotesSuccess = "customer/FetchCustomerNotesSuccess",
  fetchCustomerNotesFailed = "customer/FetchCustomerNotesFailed",
  // suspiciousNotes
  fetchCustomerSuspiciousNotesRequest = "customer/FetchCustomerSuspiciousNotesRequest",
  fetchCustomerSuspiciousNotesSuccess = "customer/FetchCustomerSuspiciousNotesSuccess",
  fetchCustomerSuspiciousNotesFailed = "customer/FetchCustomerSuspiciousNotesFailed",
  // Management data
  fetchCustomerManagementDataRequest = "customer/FetchCustomerManagementDataRequest",
  fetchCustomerManagementDataSuccess = "customer/FetchCustomerManagementDataSuccess",
  fetchCustomerManagementDataFailed = "customer/FetchCustomerManagementDataFailed",
  // Payment cards
  fetchCustomerPaymentCardsRequest = "customer/FetchCustomerPaymentCardsRequest",
  fetchCustomerPaymentCardsSuccess = "customer/FetchCustomerPaymentCardsSuccess",
  fetchCustomerPaymentCardsFailed = "customer/FetchCustomerPaymentCardsFailed",
  // Main data
  fetchCustomerMainDataRequest = "customer/FetchCustomerMainDataRequest",
  fetchCustomerMainDataSuccess = "customer/FetchCustomerMainDataSuccess",
  fetchCustomerMainDataFailed = "customer/FetchCustomerMainDataFailed",
  // IPS data
  fetchCustomerSharedIpsDataRequest = "customer/FetchCustomerSharedIpsDataRequest",
  fetchCustomerSharedIpsDataSuccess = "customer/FetchCustomerSharedIpsDataSuccess",
  fetchCustomerSharedIpsDataFailed = "customer/FetchCustomerSharedIpsDataFailed",
  // Shared Reg Ips
  fetchCustomerSharedRegIpsRequest = "customer/FetchCustomerSharedRegIpsRequest",
  fetchCustomerSharedRegIpsSuccess = "customer/FetchCustomerSharedRegIpsSuccess",
  fetchCustomerSharedRegIpsFailed = "customer/FetchCustomerSharedRegIpsFailed",
}
export interface ICustomerState {
  complianceNotes: {
    data: IGetCustomerComplianceNotesResponse["data"];
    loading: boolean;
  };
  suspiciousNotes: {
    data: {
      notes: IAccountNote[];
      count: number;
      countActive: number;
    };
    loading: boolean;
  };
  notes: {
    data: {
      notes: IAccountNote[];
      count: number;
      countActive: number;
    };
    loading: boolean;
  };
  agentLogs: {
    data: {
      logs: IActivityLogsData[];
      count: number;
    };
    loading: boolean;
  };
  plans: {
    data: IGetCustomerPlansResponse["data"];
    loading: boolean;
  };
  managementData: {
    data: IGetAccountManagementDataResponse["data"];
    loading: boolean;
  };
  payment_cards: {
    loading: boolean;
    data: {
      payment_cards: {
        cards: IPaymentCard[];
        count: number;
      };
    };
  };
  mainData: {
    loading: boolean;
    data: {
      banned_account: IGetCustomerResponse["data"]["banned_account"] | null;
      funded_trader: IGetCustomerResponse["data"]["funded_trader"] | null;
    };
  };
  ipsData: {
    sharedIps: {
      loading: boolean;
      data: TGetSharedIpsResponse["data"]["sharedIps"];
    };
    sharedRegIp: {
      loading: boolean;
      data: IGetSharedRegIpsResponse["data"]["sharedRegIp"];
    };
  };
}

import {
  IGetAccountPlansDataResponse,
  IGetAccountPlansStatusResponse,
  IGetAccountsResetsRebillsNewPurchasesResponse,
  IGetAllEntriesResponse,
  IGetCustomersLoginsResponse,
  IGetPaymentCardsResponse,
  IUniqueReqIpResponse,
  TGetAccountsPurchasesResponse,
  IBasicAccountDetailsAndSalesValueResponse,
  IGetAllEntriesRequestByValuesResponse,
} from "../../api/compliance/index.types";

export type ComplianceSearchAccountsAction =
  //SearchAccounts
  | ComplianceSearchAccountsActionsFetchRequest
  | ComplianceSearchAccountsActionsFetchSuccess
  | ComplianceSearchAccountsActionsFetchFailed
  //Search logins
  | ComplianceSearchLoginsActionsFetchRequest
  | ComplianceSearchLoginsActionsFetchSuccess
  | ComplianceSearchLoginsActionsFetchFailed
  // Search unique req ip
  | ComplianceSearchUniqueReqIpActionsFetchRequest
  | ComplianceSearchUniqueReqIpActionsFetchSuccess
  | ComplianceSearchUniqueReqIpActionsFetchFailed
  // SearchPaymentCards
  | ComplianceSearchPaymentCardsActionsFetchRequest
  | ComplianceSearchPaymentCardsActionsFetchSuccess
  | ComplianceSearchPaymentCardsActionsFetchFailed
  // SearchPlans
  | ComplianceSearchPlansActionsFetchRequest
  | ComplianceSearchPlansActionsFetchSuccess
  | ComplianceSearchPlansActionsFetchFailed
  // SearchPlansStatus
  | ComplianceSearchPlansStatusActionsFetchRequest
  | ComplianceSearchPlansStatusActionsFetchSuccess
  | ComplianceSearchPlansStatusActionsFetchFailed
  // SearchPurchases
  | ComplianceSearchPurchasesActionsFetchRequest
  | ComplianceSearchPurchasesActionsFetchSuccess
  | ComplianceSearchPurchasesActionsFetchFailed
  // SearchResetsRebillsNewPurchases
  | ComplianceSearchResetsRebillsNewPurchasesActionsFetchRequest
  | ComplianceSearchResetsRebillsNewPurchasesActionsFetchSuccess
  | ComplianceSearchResetsRebillsNewPurchasesActionsFetchFailed
  // BasicAccountDetailsAndSalesValue
  | ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchRequest
  | ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchSuccess
  | ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchFailed
  // AccountsOnNameVariations
  | ComplianceSearchAccountsOnNameVariationsActionsFetchRequest
  | ComplianceSearchAccountsOnNameVariationsActionsFetchSuccess
  | ComplianceSearchAAccountsOnNameVariationsActionsFetchFailed
  // AllAccountSearchByPhone
  | ComplianceSearchAccountsByPhoneActionsFetchRequest
  | ComplianceSearchAccountsByPhoneActionsFetchSuccess
  | ComplianceSearchAccountsByPhoneActionsFetchFailed;

//SearchAccounts
export interface ComplianceSearchAccountsActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsRequest;
}
export interface ComplianceSearchAccountsActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsSuccess;
  payload: IGetAllEntriesResponse["data"]["accounts_table"];
}
export interface ComplianceSearchAccountsActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsFailed;
}
//Search logins
export interface ComplianceSearchLoginsActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsRequest;
}
export interface ComplianceSearchLoginsActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsSuccess;
  payload: IGetCustomersLoginsResponse["data"]["logins"];
}
export interface ComplianceSearchLoginsActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsFailed;
}
// Search unique req ip
export interface ComplianceSearchUniqueReqIpActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpRequest;
}
export interface ComplianceSearchUniqueReqIpActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpSuccess;
  payload: IUniqueReqIpResponse["data"]["usageData"];
}
export interface ComplianceSearchUniqueReqIpActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpFailed;
}
// SearchPaymentCards
export interface ComplianceSearchPaymentCardsActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsRequest;
}
export interface ComplianceSearchPaymentCardsActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsSuccess;
  payload: IGetPaymentCardsResponse["data"]["paymentCards"];
}
export interface ComplianceSearchPaymentCardsActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsFailed;
}
// SearchPlans
export interface ComplianceSearchPlansActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansRequest;
}
export interface ComplianceSearchPlansActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansSuccess;
  payload: IGetAccountPlansDataResponse["data"];
}
export interface ComplianceSearchPlansActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansFailed;
}
// SearchPlansStatus
export interface ComplianceSearchPlansStatusActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusRequest;
}
export interface ComplianceSearchPlansStatusActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusSuccess;
  payload: IGetAccountPlansStatusResponse["data"]["accounts"];
}
export interface ComplianceSearchPlansStatusActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusFailed;
}
// SearchPurchases
export interface ComplianceSearchPurchasesActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesRequest;
}
export interface ComplianceSearchPurchasesActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesSuccess;
  payload: TGetAccountsPurchasesResponse["data"]["purchasesHistory"];
}
export interface ComplianceSearchPurchasesActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesFailed;
}
//SearchResetsRebillsNewPurchases
export interface ComplianceSearchResetsRebillsNewPurchasesActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesRequest;
}
export interface ComplianceSearchResetsRebillsNewPurchasesActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesSuccess;
  payload: IGetAccountsResetsRebillsNewPurchasesResponse["data"]["accounts"];
}
export interface ComplianceSearchResetsRebillsNewPurchasesActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesFailed;
}
// BasicAccountDetailsAndSalesValue
export interface ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueRequest;
}
export interface ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueSuccess;
  payload: IBasicAccountDetailsAndSalesValueResponse["data"]["accounts"];
}
export interface ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueFailed;
}
// AccountsOnNameVariations
export interface ComplianceSearchAccountsOnNameVariationsActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsRequest;
}
export interface ComplianceSearchAccountsOnNameVariationsActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsSuccess;
  payload: IGetAllEntriesRequestByValuesResponse["data"];
}
export interface ComplianceSearchAAccountsOnNameVariationsActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsFailed;
}
// AllAccountSearchByPhone
export interface ComplianceSearchAccountsByPhoneActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneRequest;
}
export interface ComplianceSearchAccountsByPhoneActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneSuccess;
  payload: IGetAllEntriesRequestByValuesResponse["data"];
}
export interface ComplianceSearchAccountsByPhoneActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneFailed;
}

export enum ComplianceSearchAccountsActionType {
  //SearchAccounts
  fetchComplianceSearchAccountsRequest = "compliance/fetchComplianceSearchAccountsRequest",
  fetchComplianceSearchAccountsSuccess = "compliance/fetchComplianceSearchAccountsSuccess",
  fetchComplianceSearchAccountsFailed = "compliance/fetchComplianceSearchAccountsFailed",
  //Search logins
  fetchComplianceSearchLoginsRequest = "compliance/fetchComplianceSearchLoginsRequest",
  fetchComplianceSearchLoginsSuccess = "compliance/fetchComplianceSearchLoginsSuccess",
  fetchComplianceSearchLoginsFailed = "compliance/fetchComplianceSearchLoginsFailed",
  // Search unique req ip
  fetchComplianceSearchUniqueReqIpRequest = "compliance/fetchComplianceSearchUniqueReqIpRequest",
  fetchComplianceSearchUniqueReqIpSuccess = "compliance/fetchComplianceSearchUniqueReqIpSuccess",
  fetchComplianceSearchUniqueReqIpFailed = "compliance/fetchComplianceSearchUniqueReqIpFailed",
  // SearchPaymentCards
  fetchComplianceSearchPaymentCardsRequest = "compliance/fetchComplianceSearchPaymentCardsRequest",
  fetchComplianceSearchPaymentCardsSuccess = "compliance/fetchComplianceSearchPaymentCardsSuccess",
  fetchComplianceSearchPaymentCardsFailed = "compliance/fetchComplianceSearchPaymentCardsFailed",
  // SearchPlans
  fetchComplianceSearchPlansRequest = "compliance/fetchComplianceSearchPlansRequest",
  fetchComplianceSearchPlansSuccess = "compliance/fetchComplianceSearchPlansSuccess",
  fetchComplianceSearchPlansFailed = "compliance/fetchComplianceSearchPlansFailed",
  // SearchPlansStatus
  fetchComplianceSearchPlansStatusRequest = "compliance/fetchComplianceSearchPlansStatusRequest",
  fetchComplianceSearchPlansStatusSuccess = "compliance/fetchComplianceSearchPlansStatusSuccess",
  fetchComplianceSearchPlansStatusFailed = "compliance/fetchComplianceSearchPlansStatusFailed",
  // SearchPurchases
  fetchComplianceSearchPurchasesRequest = "compliance/fetchComplianceSearchPurchasesRequest",
  fetchComplianceSearchPurchasesSuccess = "compliance/fetchComplianceSearchPurchasesSuccess",
  fetchComplianceSearchPurchasesFailed = "compliance/fetchComplianceSearchPurchasesFailed",
  // SearchResetsRebillsNewPurchases
  fetchSearchResetsRebillsNewPurchasesRequest = "compliance/fetchSearchResetsRebillsNewPurchasesRequest",
  fetchSearchResetsRebillsNewPurchasesSuccess = "compliance/fetchSearchResetsRebillsNewPurchasesSuccess",
  fetchSearchResetsRebillsNewPurchasesFailed = "compliance/fetchSearchResetsRebillsNewPurchasesFailed",
  // BasicAccountDetailsAndSalesValue
  fetchBasicAccountDetailsAndSalesValueRequest = "compliance/fetchBasicAccountDetailsAndSalesValueRequest",
  fetchBasicAccountDetailsAndSalesValueSuccess = "compliance/fetchBasicAccountDetailsAndSalesValueSuccess",
  fetchBasicAccountDetailsAndSalesValueFailed = "compliance/fetchBasicAccountDetailsAndSalesValueFailed",
  // AccountsOnNameVariations
  fetchSearchAccountsOnNameVariationsRequest = "compliance/fetchSearchAccountsOnNameVariationsRequest",
  fetchSearchAccountsOnNameVariationsSuccess = "compliance/fetchSearchAccountsOnNameVariationsSuccess",
  fetchSearchAccountsOnNameVariationsFailed = "compliance/fetchSearchAccountsOnNameVariationsFailed",
  // AllAccountSearchByPhone
  fetchComplianceAccountsByPhoneRequest = "compliance/fetchComplianceAccountsByPhoneRequest",
  fetchComplianceAccountsByPhoneSuccess = "compliance/fetchComplianceAccountsByPhoneSuccess",
  fetchComplianceAccountsByPhoneFailed = "compliance/fetchComplianceAccountsByPhoneFailed",
}

export interface IComplianceSearchAccountsState {
  accounts_table: {
    data: IGetAllEntriesResponse["data"]["accounts_table"];
    loading: boolean;
  };
  logins: {
    data: IGetCustomersLoginsResponse["data"]["logins"];
    loading: boolean;
  };
  uniqueReqIp: {
    data: IUniqueReqIpResponse["data"]["usageData"];
    loading: boolean;
  };
  paymentCards: {
    data: IGetPaymentCardsResponse["data"]["paymentCards"];
    loading: boolean;
  };
  plans: {
    data: IGetAccountPlansDataResponse["data"];
    loading: boolean;
  };
  plansStatus: {
    data: IGetAccountPlansStatusResponse["data"]["accounts"];
    loading: boolean;
  };
  purchasesHistory: {
    data: TGetAccountsPurchasesResponse["data"]["purchasesHistory"];
    loading: boolean;
  };
  resetsRebillsNewPurchases: {
    data: IGetAccountsResetsRebillsNewPurchasesResponse["data"]["accounts"];
    loading: boolean;
  };
  basicAccountDetailsAndSalesValue: {
    data: IBasicAccountDetailsAndSalesValueResponse["data"]["accounts"];
    loading: boolean;
  };
  searchOnNameVariations: {
    data: IGetAllEntriesRequestByValuesResponse["data"];
    loading: boolean;
  };
  searchOnPhoneVariations: {
    data: IGetAllEntriesRequestByValuesResponse["data"];
    loading: boolean;
  };
}

import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { DATE_FORMAT_hhmmssAzz } from "../../../../../constants";
import { CopyToClipboard } from "../../../../../components/ui";

const TableBody = (): React.ReactElement => {
  const { data } = useSelector((state) => state.complianceSearch.logins);

  return (
    <tbody>
      {data.rows.map((r) => {
        return (
          <tr key={r.timestamp}>
            <td>{moment(r.timestamp).tz("America/Chicago").format(DATE_FORMAT_hhmmssAzz)}</td>
            <td>
              <CopyToClipboard value={r?.account?.email} />
            </td>
            <td>
              <CopyToClipboard value={r.ip} />
            </td>
            <td>
              {r.success ? <b style={{ color: "green" }}>✔</b> : <b style={{ color: "red" }}>✘</b>}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;

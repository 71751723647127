import React from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import copyToClipboardInXlsFormat, {
  IColum,
} from "../../../../../../utils/copyToClipboardInXlsFormat";
import useGeneralStyles from "../../../../../../useGeneralStyles";
import { CENTS_IN_DOLLAR } from "../../../../../../constants";

const COLUMNS: Array<IColum> = [
  {
    by: "id",
    title: "Account ID",
    path: "id",
  },
  {
    by: "total_spent",
    title: "Total spent",
    path: "total_spent",
    copyToClipBoardCallBack: (value) => (+value / CENTS_IN_DOLLAR).toString(),
  },
  {
    by: "name",
    title: "Name",
    path: "name",
  },
  {
    by: "email",
    title: "Email",
    path: "email",
  },
  {
    by: "reg_ip",
    title: "Reg IP",
    path: "reg_ip",
  },
  {
    by: "phone",
    title: "Phone",
    path: "phone",
  },
  {
    by: "address",
    title: "Address",
    path: "address",
  },
  {
    by: "address2",
    title: "Address 2",
    path: "address2",
  },
  {
    by: "city",
    title: "City",
    path: "city",
  },
  {
    by: "stateprov",
    title: "Stateprov",
    path: "stateprov",
  },
  {
    by: "country",
    title: "Country",
    path: "country",
  },
  {
    by: "joined",
    title: "Joined",
    path: "joined",
  },
];

const TableHead = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const { data } = useSelector((state) => state.complianceSearch.basicAccountDetailsAndSalesValue);
  const handleCopyToClipBoard = () => copyToClipboardInXlsFormat(data, COLUMNS);

  return (
    <thead>
      <tr className={generalClasses.tableName}>
        <td colSpan={16}>
          <IconButton size="small" onClick={handleCopyToClipBoard}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
        </td>
      </tr>
      <tr>
        {COLUMNS.map((r) => (
          <td key={r.title}>{r.title}</td>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;

import React from "react";
import { IconButton } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSelector } from "react-redux";

import { TableDataCellWithSort } from "../../../../../components/ui";
import { useComplianceSearchContext } from "../../../../../context";
import { TBy } from "../../../../../reducers/customersReducer";
import newSortBy from "../../../../../utils/newSortBy";
import useGeneralStyles from "../../../../../useGeneralStyles";
import copyToClipboardInXlsFormat from "../../../../../utils/copyToClipboardInXlsFormat";

const COLUMNS = [
  {
    by: "reg_ip",
    title: "RegIp",
    path: "reg_ip",
  },
  {
    by: "count",
    title: "Unique email",
    path: "used_times",
  },
];

const TableHead = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const {
    unique_registration_ip_table: { sortBy, setSortBy },
  } = useComplianceSearchContext();
  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });
  const { data } = useSelector((state) => state.complianceSearch.uniqueReqIp);

  const handleCopyToClipBoard = () => copyToClipboardInXlsFormat(data, COLUMNS);

  return (
    <thead>
      <tr className={generalClasses.tableName}>
        <td colSpan={2}>
          <IconButton size="small" onClick={handleCopyToClipBoard}>
            <FileCopyIcon fontSize="inherit" />
          </IconButton>
          <span>Unique emails per registration IP</span>
        </td>
      </tr>

      <tr>
        {COLUMNS.map((c) => (
          <TableDataCellWithSort
            key={c.by}
            sortBy={sortBy}
            by={c.by}
            title={c.title}
            newSortBy={handleSortBy}
          />
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;

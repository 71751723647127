import React from "react";
import moment from "moment-timezone";
import cx from "classnames";

import { ITradedAccount } from "../../../../../../api/funds/index.types";
import { checkIsCompletedAccount } from "../../../../utils";
import {
  HOURS_12_FORMAT_WITH_DATE,
  INVOICE_LIVE_SIM_STATUSES_OPTIONS,
  INVOICE_LIVE_STATUSES_OPTIONS,
  PROCESSING_STATUSES_OPTIONS,
} from "../../../../../../constants";
import AccountsProcessingAndInvoiceStatusSelect from "../../../AccountsProcessingAndInvoiceStatusSelect";
import useStyles from "./useStyles";

interface IProps {
  accountsToShow: ITradedAccount[];
  listItems: ITradedAccount[];
  priorAccounts: ITradedAccount[];
  handleChangeProcessingStatus: (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    id: number,
    accounts_id: number
  ) => void;
  handleChangeInvoiceStatus: (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
    id: number,
    accounts_id: number
  ) => void;
}
const AccountsList: React.FC<IProps> = ({
  accountsToShow,
  listItems,
  priorAccounts,
  handleChangeProcessingStatus,
  handleChangeInvoiceStatus,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.gridContainer}>
      <div>
        <b>Account creation time</b>
      </div>
      <div>
        <b>Account name</b>
      </div>
      <div>
        <b>Prop firm</b>
      </div>
      <div>
        <b>Type</b>
      </div>
      <div>
        <b>Initial TCP</b>
      </div>
      <div>
        <b>Initial TCP account</b>
      </div>
      <div>
        <b>E2T Account name</b>
      </div>
      <div>
        <b>Processing status</b>
      </div>
      <div>
        <b>Invoice status</b>
      </div>

      {accountsToShow.map(
        (
          {
            id,
            failed_timestamp,
            timestamp,
            e2t_account_id,
            prop_firm,
            account_type,
            processing_status,
            accounts_id,
            invoice_status,
            initial_tcp,
            initial_tcp_account_id,
            evaluation_completed_history,
          },
          index
        ) => {
          const prevAccount = accountsToShow[index - 1];
          const isSubAccount =
            prevAccount &&
            initial_tcp_account_id &&
            (initial_tcp_account_id === prevAccount.id ||
              initial_tcp_account_id === prevAccount.initial_tcp_account_id);

          const initialTCPAccount = [...priorAccounts, ...listItems].find(
            ({ id }) => id === initial_tcp_account_id
          ) || { e2t_account_id: null };

          const isCompletedAccount = checkIsCompletedAccount(processing_status);
          const time = moment(failed_timestamp || timestamp)
            .tz("America/Chicago")
            .format(HOURS_12_FORMAT_WITH_DATE);

          return (
            <React.Fragment key={id}>
              <div
                className={cx(classes.gridCell, {
                  [classes.subAccount]: isSubAccount,
                })}
              >
                {time}
              </div>
              <div className={classes.gridCell}>{e2t_account_id}</div>
              <div className={classes.gridCell}>{prop_firm}</div>
              <div className={classes.gridCell}>{account_type}</div>
              <div className={classes.gridCell}>{initial_tcp}</div>
              <div className={classes.gridCell}>{initialTCPAccount.e2t_account_id}</div>
              <div className={classes.gridCell}>{evaluation_completed_history?.e2t_account_id}</div>
              <div>
                {!isCompletedAccount ? (
                  <AccountsProcessingAndInvoiceStatusSelect
                    options={PROCESSING_STATUSES_OPTIONS}
                    name="processing_status"
                    label=""
                    value={processing_status || ""}
                    id={id}
                    accounts_id={accounts_id}
                    handleChange={handleChangeProcessingStatus}
                  />
                ) : (
                  processing_status
                )}
              </div>
              <div>
                {!isCompletedAccount ? (
                  <AccountsProcessingAndInvoiceStatusSelect
                    options={
                      account_type === "LiveSim"
                        ? INVOICE_LIVE_SIM_STATUSES_OPTIONS
                        : INVOICE_LIVE_STATUSES_OPTIONS
                    }
                    name="invoice_status"
                    label=""
                    value={invoice_status || ""}
                    id={id}
                    accounts_id={accounts_id}
                    handleChange={handleChangeInvoiceStatus}
                  />
                ) : (
                  invoice_status
                )}
              </div>
            </React.Fragment>
          );
        }
      )}
    </div>
  );
};

export default AccountsList;
